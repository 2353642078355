import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryHateoasClientService } from '@lib/common-sdk';
import { ProjectBranchViewModel } from '@lib/api-interface';
import axios from 'axios';

export const useProjectBranchView = (projectBranchId: string | undefined) => {
  const queryKey = ['projectBranchViews', projectBranchId];
  const queryClient = useQueryClient();

  const query = useQuery(
    queryKey,
    (context) => {
      return ReactQueryHateoasClientService.findOneByAggregateId<ProjectBranchViewModel>('projectBranchViews', 'projectBranchId', projectBranchId);
    },
    {
      staleTime: 3000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};

export const getProjectBranchViewForClient = async () => {
  return await axios.get(`${process.env['REACT_APP_API_URL']}/api/project-branch-view`).then((res) => res.data);
};
