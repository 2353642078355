import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, ExportToExcelIcon, NavigateButton, RenderIf } from '@lib/ui-components';
import { extractValue, HateoasRestApiClientService, longNumericToString } from '@lib/common-sdk';
import axios, { AxiosResponse } from 'axios';
import { DataTableFilter, TableFilterItem } from './data-table-filter/data-table-filter';
import { useTranslation } from 'react-i18next';
import styles from '../data-table/styles.module.css';
import { DataSearchField } from './table-search-field/data-search-field';
import { ArrowDownward } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import { StyledBooleanTrueSign } from './StyledBooleanTrueSign';
import { format, isValid, parseISO } from 'date-fns';
import LinearProgress from '@mui/material/LinearProgress';
import { Checkbox, TablePagination } from '@mui/material';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { useNavigate } from 'react-router-dom';
import { navigationLink } from './navigate-to-row-button';

export interface DataTableColumnEnumValue {
  label: string;
  value: string;
}

export interface DataTableCustomValueMapping {
  value: any;
  label: string | JSX.Element;
  stringLabel?: string; // used for excel export as an alternative to label with the JSX.Element type
  excludeFromFilter?: boolean;
}

export interface SingleLineProps {
  maxCellWidthInRem?: number;
}

export interface DataTableColumn {
  key: string;
  label: string;
  type:
    | 'text'
    | 'text-chip'
    | 'boolean'
    | 'numeric'
    | 'long-numeric'
    | 'enum'
    | 'date'
    | 'date-year-month'
    | 'date-time'
    | 'string-array-boolean'
    | 'string-array-boolean-aggregate'
    | 'object';
  numericPrecision?: number;
  align?: 'left' | 'center' | 'right';
  grow?: number;
  minWidth?: string;
  maxWidth?: string;
  enumValues?: DataTableColumnEnumValue[];
  stringArrayValue?: string;
  bold?: boolean;
  highlight?: boolean;
  color?: string;
  colorOnCondition?: (row: any) => string;
  linkPath?: string;
  linkRowId?: string;
  dateFormat?: string;
  customValueMappings?: DataTableCustomValueMapping[];
  excludeFromFilter?: boolean;
  joinedColumns?: DataTableColumn[];
  singleLine?: SingleLineProps;
  renderer?: (row: any) => JSX.Element | string;
  hidden?: boolean;
  showEmptyIfTrue?: (row: any) => boolean;
  expressionForBooleanType?: (row: any) => boolean;
  additionalElement?: (row: any) => JSX.Element | string;
}

export interface DataTableRowOptionItem {
  renderer: (row: any) => JSX.Element;
  isVisible?: (row: any) => boolean;
}

export interface DataTableModelDescription {
  modelName: string;
  modelClass?: ClassConstructor<any>;
  search?: string;
  embeddedResultModelName?: string;
}

export interface DataTableRowLink {
  linkPath: string;
  linkRowId?: string;
}

export interface SelectableOptions {
  onSelectedRowsChange?: (rows: any[]) => void;
  initialSelections?: any[];
  comparatorField: string;
  singleSelect?: boolean;
}

export interface SmartDataTableProps {
  modelDef?: DataTableModelDescription;
  fetchUrl?: string;
  fetchPostUrl?: string;
  fetchFilters?: any;
  data?: any;
  columns: DataTableColumn[];
  rowOptions?: DataTableRowOptionItem[];
  rowOptionsMinWidth?: string;
  columnsMinWidth?: string;
  refreshKey: number;
  noPagination?: boolean;
  noFilters?: boolean;
  preSortIndexes?: number[];
  ignorePreSortIndexes?: boolean;
  onRowLoaded?: (row: any) => void; // when table fetches data, calls this function once for each loaded row
  rowLink?: DataTableRowLink;
  defaultPageSize?: number;
  initialSort?: SortingInfo;
  selectable?: boolean;
  selectableOptions?: SelectableOptions;
  onRowClicked?: (row: any) => void;
  excel?: boolean;
  onTableLoaded?: (rows: any) => void; // after table fetched data, calls this function once for table loaded
  defaultTableFilters?: Array<TableFilterItem>; // filters on client side
  onDefaultTableFiltersUpdated?: (filters: Array<TableFilterItem>) => void; // filters on client side
  contentMaxHeightAsAlmostOneScreen?: boolean; // used for scroll only table rows and have content above and below visible
  customRowStyleIfTrue?: {
    condition: (row: any) => boolean;
    style: React.CSSProperties;
  };
}

const StyledTableContainer = styled.div`
  overflow: auto;
  padding-bottom: 9px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 0.5rem;
    margin: 0 0.1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }
`;

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  color: rgb(24, 51, 98);
  font-size: 0.8rem;

  & tr:hover {
    background-color: #dddddd;
  }
`;

const StyledTableHeader = styled.th`
  padding: 0.8rem 1rem;
  background-color: rgb(248, 248, 248);
  min-height: 52px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  position: sticky;
  top: 0;
`;

const TableHeaderLabel = styled.div<Pick<DataTableColumn, 'align'>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : 'left')};
  gap: 0.5rem;
`;

const StyledTableCell = styled.td<Pick<DataTableColumn, 'align'>>`
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  border-right-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.12);
  border-right-style: solid;
  text-align: ${({ align }) => (align ? align : 'left')};
  padding: 0.5rem 1rem;
`;

const StyledTextChip = styled.div<{ normal?: boolean }>`
  padding: 0.5rem 0.9rem;
  border-radius: 1rem;
  background-color: ${(props) => (props.normal ? '#c5c5c5' : '#f8e48d')};
  width: fit-content;
  white-space: nowrap;
`;

const StyledPaginationBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const StyledAggregateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
`;

interface SortingInfo {
  columnIndex: number;
  ascending: boolean;
}

export const DataTable = (props: SmartDataTableProps) => {
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState(new Array<any>());

  // Loading of data - change of key reloads data
  React.useEffect(() => {
    let isCancelled = false;
    setPending(true);
    setRows([]);
    if (props.modelDef) {
      HateoasRestApiClientService.findAll(props.modelDef.modelName, props.fetchFilters, undefined, {
        modelClass: props.modelDef.modelClass,
        search: props.modelDef.search,
        embeddedResultModelName: props.modelDef?.embeddedResultModelName,
      }).then((response) => {
        if (!isCancelled) {
          if (!response.failed) {
            const data = response.response || [];
            if (props.onRowLoaded) {
              data.forEach((value) => props.onRowLoaded!(value));
            }
            setRows(data);
            if (data && props.onTableLoaded) {
              props.onTableLoaded(data);
            }
          }
          setPending(false);
        }
      });
    } else if (props.fetchUrl) {
      axios
        .get(props.fetchUrl, { params: props.fetchFilters })
        .then((response: AxiosResponse) => {
          if (!isCancelled) {
            const items = response.data?.result !== undefined ? response.data?.result : response.data;
            if (props.onRowLoaded) {
              items?.forEach((value: any) => props.onRowLoaded!(value));
            }
            setRows(items);
            if (items && props.onTableLoaded) {
              props.onTableLoaded(items);
            }
            setPending(false);
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setPending(false);
          }
        });
    } else if (props.fetchPostUrl) {
      axios
        .post(props.fetchPostUrl, props.fetchFilters)
        .then((response: AxiosResponse) => {
          if (!isCancelled) {
            const items = response.data?.result !== undefined ? response.data?.result : response.data;
            if (props.onRowLoaded) {
              items?.forEach((value: any) => props.onRowLoaded!(value));
            }
            setRows(items);
            if (items && props.onTableLoaded) {
              props.onTableLoaded(items);
            }
            setPending(false);
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setPending(false);
          }
        });
    } else if (props.data) {
      if (!isCancelled) {
        setRows(props.data);
        if (props.data && props.onTableLoaded) {
          props.onTableLoaded(props.data);
        }
        setPending(false);
      }
    } else {
      throw Error('Datatable without defined fetch endpoint or model');
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [props.refreshKey, props.fetchFilters]);

  return <DataTableWithData rows={rows} pending={pending} {...props} />;
};

// The Material UI way...
const LinearIndeterminate = () => {
  return (
    <div className={styles['progressContainer']}>
      <LinearProgress />
    </div>
  );
};

interface SmartDataTableWithDataProps extends SmartDataTableProps {
  rows: any[];
  pending: boolean;
}

const DataTableWithData = (props: SmartDataTableWithDataProps) => {
  const rows = useMemo(() => {
    for (let i = 0; i < props.rows.length; i += 1) {
      props.rows[i]['__rowIdx'] = i;
    }
    return props.rows;
  }, [props.rows]);

  const [sortInfo, setSortInfo] = useState<SortingInfo>(
    props.initialSort
      ? props.initialSort
      : {
          columnIndex: 0,
          ascending: true,
        },
  );
  const [filterText, setFilterText] = useState('');
  const [activeFilters, setActiveFilters] = useState(new Array<TableFilterItem>());
  const [filteredItemsToDisplay, setFilteredItemsToDisplay] = useState(new Array<any>());
  const [filteredItemsToDisplaySortedIndexes, setFilteredItemsToDisplaySortedIndexes] = useState(new Array<number>());
  const [rowsPerPage, setRowsPerPage] = useState(props.defaultPageSize || 50);
  const [page, setPage] = useState(0);
  const [selectedRowIdxs, setSelectedRowIdxs] = useState(new Array<number>());
  const [disableSelection, setDisableSelection] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dateFormat = i18n.language === 'pl' ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
  const dateYearMonthFormat = i18n.language === 'pl' ? 'MM.yyyy' : 'MM/yyyy';
  const dateTimeFormat = i18n.language === 'pl' ? 'dd.MM.yyyy HH:mm:ss' : 'MM/dd/yyyy HH:mm:ss';
  const [exportButtonClicked, setExportButtonClicked] = React.useState(false);
  const [isExporting, setIsExporting] = React.useState(false);
  const [ignorePreSortIndexes, setIgnorePreSortIndexes] = React.useState(false);

  React.useEffect(() => {
    if (props.selectable && props?.selectableOptions?.onSelectedRowsChange != null) {
      props.selectableOptions.onSelectedRowsChange(selectedRowIdxs.map((idx) => rows[idx]));
    }
  }, [selectedRowIdxs]);

  React.useEffect(() => {
    setSelectedRowIdxs([]);
    setDisableSelection(false);
  }, [props.refreshKey]);

  // preselects row indexes based on initial selections
  React.useEffect(() => {
    if (props.selectableOptions?.initialSelections == undefined) {
      return;
    }
    const newSelectedRowsIdxs = new Array<number>();
    const key = props.selectableOptions?.comparatorField;
    props.selectableOptions?.initialSelections?.forEach((initialSelection) => {
      const value = initialSelection[key];
      for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
        if (rows[rowIndex][key] === value) {
          newSelectedRowsIdxs.push(rowIndex);
        }
      }
    });
    setSelectedRowIdxs(newSelectedRowsIdxs);
    setDisableSelection((props.selectableOptions?.singleSelect && newSelectedRowsIdxs.length > 0) || false);
  }, [rows]);

  // init Table Header
  const tableHeader = React.useMemo(() => {
    const header = props.columns.map((column, index) => {
      if (column.hidden) {
        return <StyledTableHeader key={`h${index}`} style={{ display: 'none' }} />;
      }
      return (
        <StyledTableHeader
          key={`h${index}`}
          style={{
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
            cursor: 'pointer',
            whiteSpace: column.maxWidth ? 'break-spaces' : 'nowrap',
          }}
          onClick={() => {
            if (props.preSortIndexes !== undefined && !ignorePreSortIndexes) {
              setIgnorePreSortIndexes(true);
            }
            setSortInfo({
              columnIndex: index,
              ascending: index === sortInfo.columnIndex ? !sortInfo.ascending : true,
            });
            setPage(0);
          }}
        >
          <TableHeaderLabel align={column.align}>
            <span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: column.label }}></span>
            {!props.noFilters && sortInfo.columnIndex === index && <ArrowDownward style={{ width: '16px', transform: sortInfo.ascending ? 'rotate(180deg)' : '' }} />}
          </TableHeaderLabel>
        </StyledTableHeader>
      );
    });
    if (props.rowOptions) {
      header.push(
        <StyledTableHeader
          key='rowOptionsHeader'
          style={{
            /* Stick to the right */
            right: 0,
            position: 'sticky',
            width: '1px', // minimal needed space
            zIndex: 1,
          }}
        >
          <TableHeaderLabel align={'left'}>
            <span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: t('options') }}></span>
          </TableHeaderLabel>
        </StyledTableHeader>,
      );
    }

    function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
      const rowIds = filteredItemsToDisplay.map((row) => row['__rowIdx']) as number[];
      if (event.target.checked) {
        const newRowIds = [...selectedRowIdxs];
        rowIds.forEach((value) => {
          if (!newRowIds.includes(value)) {
            newRowIds.push(value);
          }
        });
        setSelectedRowIdxs(newRowIds);
      } else {
        const afterClearIndexes = selectedRowIdxs.filter((value) => !rowIds.includes(value));
        setSelectedRowIdxs(afterClearIndexes);
      }
    }

    if (props.selectable) {
      header.unshift(
        <StyledTableHeader
          key='rowSelectHeader'
          style={{
            /* Stick to the right */
            right: 0,
            position: 'sticky',
            width: '1px', // minimal needed space
            zIndex: 1,
          }}
        >
          <Checkbox
            indeterminate={selectedRowIdxs.length > 0 && selectedRowIdxs.length !== filteredItemsToDisplay.length}
            checked={selectedRowIdxs.length === filteredItemsToDisplay.length && filteredItemsToDisplay.length != 0}
            disabled={props.selectableOptions?.singleSelect}
            onChange={handleSelectAll}
          />
        </StyledTableHeader>,
      );
    }
    return header;
  }, [props.columns, props.rowOptions, sortInfo, selectedRowIdxs, filteredItemsToDisplay, disableSelection]);

  function getNestedObjectValue(obj: any, key: string) {
    return key.split('.').reduce((o, x) => (typeof o == 'undefined' || o === null ? o : o[x]), obj);
  }

  function sortComparatorFunction(filteredItemsToDisplay: any[], i1: number, i2: number) {
    const n1 = filteredItemsToDisplay[i1];
    const n2 = filteredItemsToDisplay[i2];
    const val1 = getNestedObjectValue(n1, props.columns[sortInfo.columnIndex].key);
    const val2 = getNestedObjectValue(n2, props.columns[sortInfo.columnIndex].key);
    const selectedColumnSortResult = val1 == val2 ? 0 : val1 > val2 ? 1 : -1;
    if (props.preSortIndexes !== undefined && !ignorePreSortIndexes) {
      for (const preSortIndex of props.preSortIndexes) {
        const sortIndex = preSortIndex < 0 ? -preSortIndex : preSortIndex;
        const invert = preSortIndex < 0;
        const valFirst1 = n1[props.columns[sortIndex].key];
        const valFirst2 = n2[props.columns[sortIndex].key];
        let sortResult = valFirst1 == valFirst2 ? 0 : valFirst1 > valFirst2 ? 1 : -1;
        if (invert) {
          sortResult = -sortResult;
        }
        if (sortResult !== 0) {
          return sortResult;
        }
      }
    }
    return selectedColumnSortResult;
  }

  // filter data
  React.useEffect(() => {
    const filteredItemsByFilters = activeFilters.length > 0 ? filtersItemsBasedOnActiveFilters(rows) : rows;
    const filterTextLowerCase = filterText.toLowerCase();
    const filteredItemsToDisplay = filterTextLowerCase !== '' ? filterItemsBasedOnSearchInput(filteredItemsByFilters, filterTextLowerCase) : filteredItemsByFilters;
    const indexes = new Array<number>(filteredItemsToDisplay.length);
    for (let i = 0; i < indexes.length; i += 1) {
      indexes[i] = i;
    }
    indexes.sort((i1, i2) => {
      return sortComparatorFunction(filteredItemsToDisplay, i1, i2);
    });

    if (!sortInfo.ascending) {
      indexes.reverse();
    }
    setFilteredItemsToDisplay(filteredItemsToDisplay);
    setFilteredItemsToDisplaySortedIndexes(indexes);
    // eslint-disable-next-line
  }, [rows, filterText, activeFilters]);

  function filterTableOnFiltersChange(filters: Array<TableFilterItem>) {
    const newFilters = [...filters];
    setActiveFilters(newFilters);
    setPage(0);
  }

  function dateToString(column: DataTableColumn, value: any, endOfDay = false) {
    try {
      if (column.type === 'date') {
        if (value != null && value !== '') {
          const date: Date = parseISO(value);
          if (isValid(date)) {
            return format(date, 'yyyy-MM-dd');
          }
        }
      } else if (column.type === 'date-year-month') {
        if (value != null && value !== '') {
          const date: Date = parseISO(value);
          if (isValid(date)) {
            return format(date, 'yyyy-MM');
          }
        }
      } else if (column.type === 'date-time') {
        if (value != null && value !== '') {
          const date: Date = parseISO(value);
          if (isValid(date)) {
            if (endOfDay) {
              date.setHours(23, 59, 59, 999);
            }
            return format(date, 'yyyy-MM-dd HH:mm:ss');
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    return '';
  }

  function filtersItemsBasedOnActiveFilters(rows: any[]) {
    return rows.filter((row: any) => {
      for (const filter of activeFilters) {
        if (filter.value != null) {
          const rowValue = extractValue(row, filter.column.key);

          if (filter.column.type === 'boolean') {
            if (filter.column.expressionForBooleanType) {
              const filterBooleanValue = filter.value === 'true';
              return filter.column.expressionForBooleanType(row) === filterBooleanValue;
            }
            const boolValue = `${rowValue}` === 'true' ? 'true' : 'false';
            if (boolValue !== filter.value) {
              return false;
            }
          } else if (filter.column.type === 'numeric') {
            const filterValue = `${filter.value}`.replace(',', '.');
            if (!`${rowValue}`.startsWith(filterValue)) {
              return false;
            }
          } else if (filter.column.type === 'string-array-boolean') {
            const arrayRowValue = rowValue as string[];
            const search = arrayRowValue.includes(filter.column.stringArrayValue as string);
            const filterBoolean = filter.value === 'true';
            if (search !== filterBoolean) {
              return false;
            }
          } else if (filter.column.type === 'long-numeric') {
            const filterValue = `${filter.value}`.replace('.', ',');
            const stringValue = longNumericToString(rowValue, filter.column.numericPrecision, ',');
            if (!`${stringValue}`.startsWith(filterValue)) {
              return false;
            }
          } else if (filter.column.type === 'enum') {
            if (`${filter.value}` !== `${rowValue}`) {
              return false;
            }
          } else if (filter.column.type === 'date' || filter.column.type === 'date-year-month' || filter.column.type === 'date-time') {
            const [fromDate, toDate] = filter.value as [Date | null, Date | null];
            const from = dateToString(filter.column, fromDate);
            const to = dateToString(filter.column, toDate, filter.column.type === 'date-time');
            const rowValueAsString = dateToString(filter.column, rowValue);
            if (from && to) {
              if (rowValueAsString < from || rowValueAsString > to) {
                return false;
              }
            } else if (from) {
              if (rowValueAsString === '') return false;
              if (rowValueAsString < from) {
                return false;
              }
            } else if (to) {
              if (rowValueAsString === '') return false;
              if (rowValueAsString > to) {
                return false;
              }
            }
            return true;
          } else if (filter.column.type === 'string-array-boolean-aggregate') {
            const arrayKey = filter.column.key.substring(0, filter.column.key.lastIndexOf('.'));
            const valueKey = filter.column.key.substring(filter.column.key.lastIndexOf('.') + 1, filter.column.key.length);
            const rowValueArray = extractValue(row, arrayKey) as string[];
            const boolValue = rowValueArray.includes(valueKey);
            if (`${boolValue}` !== filter.value) {
              return false;
            }
          } else {
            if (!`${rowValue}`.toLowerCase().includes(`${filter.value}`.toLowerCase())) {
              return false;
            }
          }
        }
      }
      return true;
    });
  }

  function filterItemsBasedOnSearchInput(filteredItemsByFilters: any[], filterTextLowerCase: string) {
    return filteredItemsByFilters.filter((item: any) => {
      for (const column of props.columns) {
        const value = extractValue(item, column.key);

        if (column.type === 'text') {
          if (`${value}`.toLowerCase().includes(filterTextLowerCase)) {
            return true;
          }
        } else if (column.type === 'enum') {
          if (column.enumValues != null) {
            if (column.enumValues.find((item) => item.label.toLowerCase().startsWith(filterTextLowerCase)) != null) {
              return true;
            }
          }
        } else if (column.type === 'numeric') {
          const filterValue = filterTextLowerCase.replace(',', '.');
          if (`${value}`.startsWith(filterValue)) {
            return true;
          }
        } else if (column.type === 'long-numeric') {
          const filterValue = filterTextLowerCase.replace('.', ',');
          const stringValue = longNumericToString(value, column.numericPrecision, ',');
          if (stringValue.startsWith(filterValue)) {
            return true;
          }
        }
      }
      return false;
    });
  }

  React.useEffect(() => {
    const indexes = new Array<number>(filteredItemsToDisplay.length);
    for (let i = 0; i < indexes.length; i += 1) {
      indexes[i] = i;
    }

    indexes.sort((i1, i2) => {
      return sortComparatorFunction(filteredItemsToDisplay, i1, i2);
    });

    if (!sortInfo.ascending) {
      indexes.reverse();
    }
    setFilteredItemsToDisplaySortedIndexes(indexes);
  }, [sortInfo]);

  const rowsData = React.useMemo(
    () =>
      filteredItemsToDisplaySortedIndexes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((indexToDisplay) => {
        const row = filteredItemsToDisplay[indexToDisplay];

        function handleSelection(event: React.ChangeEvent<HTMLInputElement>) {
          const rowIdx = row['__rowIdx'];
          if (event.target.checked) {
            if (!selectedRowIdxs.includes(rowIdx)) {
              const newSelectedRowIds = [...selectedRowIdxs, rowIdx];
              setSelectedRowIdxs(newSelectedRowIds);
              setDisableSelection((props.selectableOptions?.singleSelect && newSelectedRowIds.length > 0) || false);
            }
          } else {
            const newSelectedRowIds = selectedRowIdxs.filter((value) => value !== rowIdx);
            setSelectedRowIdxs(newSelectedRowIds);
            setDisableSelection((props.selectableOptions?.singleSelect && newSelectedRowIds.length > 0) || false);
          }
        }

        const cells = props.columns.map((column, index) => {
          const rowProps: any = { key: `dc${index}` };
          rowProps.style = { whiteSpace: 'break-spaces' };
          if (column.showEmptyIfTrue && column.showEmptyIfTrue(row)) {
            return <StyledTableCell {...rowProps} />;
          }
          if (props.rowLink) {
            rowProps.onClick = () => navigate(navigationLink(row, props.rowLink!.linkPath, props.rowLink?.linkRowId));
            rowProps.style.cursor = 'pointer';
          } else if (props.onRowClicked) {
            rowProps.onClick = () => props.onRowClicked!(row);
            rowProps.style.cursor = 'pointer';
          }
          if (column.color) {
            rowProps.style.color = column.color;
          }
          if (column.colorOnCondition) {
            rowProps.style.color = column.colorOnCondition(row);
          }
          if (column.singleLine) {
            rowProps.style.whiteSpace = 'nowrap';
            rowProps.style.textOverflow = 'ellipsis';
            rowProps.style.overflow = 'hidden';
            rowProps.style.maxWidth = (column.singleLine.maxCellWidthInRem || 50) + 'rem';
          }
          if (column.hidden) {
            return <StyledTableCell style={{ display: 'none' }} />;
          }
          if (column.renderer) {
            return (
              <StyledTableCell align={column.align} {...rowProps}>
                {column.renderer(row)}
              </StyledTableCell>
            );
          }
          if (column.additionalElement) {
            return (
              <StyledTableCell {...rowProps}>
                {printColumn(row, column)} {column.additionalElement(row)}
              </StyledTableCell>
            );
          }
          if (column.joinedColumns && column.joinedColumns.length > 0) {
            const label =
              printColumn(row, column) +
              ' ' +
              column.joinedColumns
                .map((joinedColumn) => {
                  return printColumn(row, joinedColumn);
                })
                .filter((value) => value !== undefined && value !== '')
                .join(' ');
            return (
              <StyledTableCell align={column.align} {...rowProps}>
                {column.linkPath !== undefined ? <NavigateButton label={label.toString()} to={{ pathname: navigationLink(row, column.linkPath, column.linkRowId) }} /> : label}
              </StyledTableCell>
            );
          }

          return (
            <StyledTableCell align={column.align} {...rowProps}>
              {column.linkPath !== undefined ? (
                <NavigateButton label={printColumn(row, column).toString()} to={{ pathname: navigationLink(row, column.linkPath, column.linkRowId) }} />
              ) : (
                printColumn(row, column)
              )}
            </StyledTableCell>
          );
        });

        if (props.rowOptions) {
          const options = props.rowOptions
            ?.filter((item) => (item.isVisible != null ? item.isVisible(row) : true))
            .map((item, index) => {
              return <div key={`ro${index}`}>{item.renderer(row)}</div>;
            });
          cells.push(
            <StyledTableCell
              key='rp'
              style={{
                /* Stick to the right */
                right: 0,
                position: 'sticky',
                backgroundColor: '#ffffff',
              }}
            >
              {<div className={styles['rowOptionsContainer']}>{options}</div>}
            </StyledTableCell>,
          );
        }

        if (props.selectable) {
          const rowIdx = row['__rowIdx'];
          cells.unshift(
            <StyledTableCell
              key='rq'
              style={{
                /* Stick to the right */
                right: 0,
                position: 'sticky',
                backgroundColor: '#ffffff',
              }}
            >
              <Checkbox checked={selectedRowIdxs.includes(rowIdx)} onChange={handleSelection} disabled={disableSelection && !selectedRowIdxs.includes(rowIdx)} />
            </StyledTableCell>,
          );
        }

        if (props.customRowStyleIfTrue && props.customRowStyleIfTrue.condition(row)) {
          return (
            <tr style={props.customRowStyleIfTrue.style} key={indexToDisplay}>
              {cells}
            </tr>
          );
        }
        return <tr key={indexToDisplay}>{cells}</tr>;
      }),
    [filteredItemsToDisplaySortedIndexes, page, rowsPerPage, selectedRowIdxs, disableSelection],
  );

  function printColumn(row: any, column: DataTableColumn, onlyStringAsResult = false, dontFormatNumbers = false): string | JSX.Element {
    const value = extractValue(row, column.key);
    const customValueMapping: DataTableCustomValueMapping | undefined = column.customValueMappings?.find((customValueMapping) => customValueMapping.value === value);
    if (customValueMapping) {
      if (onlyStringAsResult) {
        return typeof customValueMapping.label === 'string' ? customValueMapping?.label : customValueMapping.stringLabel ?? '';
      }
      return customValueMapping.label;
    }
    if (column.type === 'boolean') {
      let valueIsTrue;
      if (column.expressionForBooleanType) {
        valueIsTrue = column.expressionForBooleanType(row);
      } else {
        valueIsTrue = value === true || value === 'true';
      }
      if (onlyStringAsResult) return valueIsTrue ? t('yes') : t('no');
      return valueIsTrue ? <StyledBooleanTrueSign /> : <div />;
    }
    if (column.type === 'numeric') {
      if (dontFormatNumbers) return value;
      if (value == null) return '';
      if (column.numericPrecision != null) {
        return transformToNumberString(value, column.numericPrecision);
      }
      return value.toString().replace('.', ',');
    }
    if (column.type === 'long-numeric') {
      if (value == null) return '';
      return longNumericToString(value, column.numericPrecision, ',');
    }
    if (column.type === 'date') {
      if (value != null && value !== '') {
        const date: Date = parseISO(value);
        return format(date, column.dateFormat || dateFormat);
      }
      return '';
    }
    if (column.type === 'date-year-month') {
      if (value != null && value !== '') {
        const date: Date = parseISO(value);
        return format(date, dateYearMonthFormat);
      }
      return '';
    }
    if (column.type === 'date-time') {
      if (value != null && value !== '') {
        const date: Date = parseISO(value);
        return format(date, dateTimeFormat);
      }
      return '';
    }
    if (column.type === 'enum') {
      if (column.enumValues != null && value != null) {
        const item = column.enumValues.find((item) => item.value === value);
        if (item != null) {
          return item.label;
        }
      }
      return '';
    }
    if (column.type === 'object') {
      if (!value) return '';
      let result = '';
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          const val = value[key];
          if (val != null && val !== '') {
            const translatedKey = t(key as any);
            const translatedVal = t(val as any);
            result += `${translatedKey}: ${translatedVal}\n`;
          }
        }
      }
      return result;
    }
    if (column.type === 'string-array-boolean') {
      const valueArray = value as string[];
      return valueArray.includes(column.stringArrayValue ?? '') ? t('yes') : t('no');
    }
    if (column.type === 'string-array-boolean-aggregate') {
      const valueArray = value as string[];
      if (onlyStringAsResult) {
        return (
          column.enumValues
            ?.map((value, index) => {
              return valueArray.includes(value.value) ? value.label : '';
            })
            .join(', ') ?? ''
        );
      }
      const enumValues =
        column.enumValues?.map((value, index) => {
          return (
            <StyledTextChip key={`c${index}`} normal={!valueArray.includes(value.value)}>
              {value.label}
            </StyledTextChip>
          );
        }) ?? false;
      return <StyledAggregateContainer>{enumValues}</StyledAggregateContainer>;
    }
    if (column.type === 'text-chip') {
      if (value == null) return '';
      if (Array.isArray(value)) {
        if (onlyStringAsResult) {
          return value.join(', ');
        }
        return (
          <StyledAggregateContainer>
            {value.map((item) => {
              return <StyledTextChip>{item.toString()}</StyledTextChip>;
            })}
          </StyledAggregateContainer>
        );
      }
      return <StyledTextChip>{value.toString()}</StyledTextChip>;
    }
    return value != null ? value.toString() : '';
  }

  function transformToNumberString(x: any, decimalPlaces: number) {
    const val = x
      .toString()
      .replace(/\./g, ',') // replace . with ,
      .replace(/[,](?=.*[,])/g, '') // remove all , but last
      .replace(/(?!^)-/g, ''); // remove all - but first

    if (decimalPlaces !== undefined) {
      const valueParts = val.split(',');

      if (valueParts[0] === '') {
        valueParts[0] = '0';
      } else if (valueParts[0] === '-') {
        valueParts[0] = '-0';
      } else {
        valueParts[0] = `${parseInt(valueParts[0], 10)}`;
      }

      if (decimalPlaces === 0) {
        return valueParts[0];
      }

      if (valueParts.length === 1) {
        return valueParts[0] + '.' + '0'.repeat(decimalPlaces);
      }

      const lengthOfDecimalPoints = valueParts[1].length;
      if (lengthOfDecimalPoints < decimalPlaces) {
        return valueParts[0] + '.' + valueParts[1] + '0'.repeat(decimalPlaces - lengthOfDecimalPoints);
      } else if (lengthOfDecimalPoints === decimalPlaces) {
        return valueParts[0] + '.' + valueParts[1];
      } else {
        return valueParts[0] + '.' + valueParts[1].substr(0, decimalPlaces);
      }
    }

    return val;
  }

  function toTranslations(column: DataTableColumn) {
    const result: { [key: string]: string } = {};
    column.customValueMappings?.filter((value) => typeof value.label === 'string')?.forEach((value) => (result[value.value] = value.label.toString()));
    return result;
  }

  const columnDefinitions = props.columns
    .filter((value) => value.key && value.key !== '')
    .map((value) => {
      return {
        key: value.key,
        label: value.label,
        translations: toTranslations(value),
        numericPrecision: value.numericPrecision,
        hidden: value.hidden ?? false,
      };
    });

  function formatExportData(data: any[], columns: DataTableColumn[]) {
    return data.map((row) => {
      const formattedRow: { [key: string]: any } = {};
      columns.forEach((column) => {
        formattedRow[column.key] = printColumn(row, column, true, true);
      });
      return formattedRow;
    });
  }

  function getExportData() {
    return {
      sheets: [
        {
          rows: formatExportData(filteredItemsToDisplay, props.columns),
          parameters: {
            columnDefinitions,
          },
        },
      ],
    };
  }

  React.useEffect(() => {
    async function handleClick() {
      try {
        const exportData = getExportData();
        const response = await axios({
          method: 'post',
          data: exportData,
          url: '/api/excel',
          responseType: 'blob',
        });

        const contentDispositionHeader = response.headers['content-disposition'];
        let fileName = '';

        if (contentDispositionHeader) {
          const fileNameMatch = contentDispositionHeader.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/);
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = decodeURIComponent(fileNameMatch[1]);
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error exporting to Excel:', error);
      } finally {
        setIsExporting(false);
      }
    }

    if (exportButtonClicked) {
      handleClick();
      setExportButtonClicked(false);
    }
  }, [exportButtonClicked]);

  return (
    <Paper className={styles['paperContainer']}>
      <div className={styles['dataTableContainerWrapper']}>
        <div className={props.contentMaxHeightAsAlmostOneScreen ? styles['dataTableContainerWithMaxHeight'] : styles['dataTableContainer']}>
          <RenderIf true={props.excel && rows.length > 0}>
            <div className={styles['tableToolbar']}>
              <Button
                label={t('exportToExcel')}
                startIcon={<ExportToExcelIcon />}
                onClick={async () => {
                  setIsExporting(true);
                  setExportButtonClicked(true);
                }}
                isDisabled={isExporting}
              />
            </div>
          </RenderIf>
          <RenderIf false={props.noFilters}>
            <div className={styles['tableToolbar']}>
              <DataTableFilter
                columns={props.columns}
                onFiltersChange={(filters) => {
                  filterTableOnFiltersChange(filters);
                  if (props.onDefaultTableFiltersUpdated !== undefined) {
                    props.onDefaultTableFiltersUpdated([...filters]);
                  }
                  setPage(0);
                }}
                initialFilters={props.defaultTableFilters}
              />
              <div>
                <DataSearchField
                  onFiltersChange={(filter) => {
                    setFilterText(filter);
                    setPage(0);
                  }}
                />
              </div>
            </div>
          </RenderIf>
          <StyledTableContainer>
            <StyledTable>
              {/* TODO: remove scrollbar from header row */}
              <thead>{tableHeader}</thead>
              <tbody>{rowsData}</tbody>
            </StyledTable>
            {props.pending && <LinearIndeterminate />}
          </StyledTableContainer>
          {!props.pending && !props.noPagination && (
            <StyledPaginationBar>
              <table>
                <tfoot>
                  <tr>
                    <TablePagination
                      count={filteredItemsToDisplaySortedIndexes.length}
                      onPageChange={(e, page) => {
                        setPage(page);
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                      }}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      showFirstButton={true}
                      showLastButton={true}
                      style={{ borderBottom: 'none' }}
                      labelRowsPerPage={t('SmartDataTable.numberOfItems')}
                      labelDisplayedRows={(args) => `${args.from}–${args.to} ${t('SmartDataTable.of')} ${args.count}`}
                    />
                  </tr>
                </tfoot>
              </table>
            </StyledPaginationBar>
          )}
        </div>
      </div>
    </Paper>
  );
};
