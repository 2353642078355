import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddIcon,
  Button,
  DataTable,
  DataTableColumn,
  DataTableRowOptionItem,
  Dialog,
  FormInputCheckbox,
  FormInputText,
  LargeAddIcon,
  Panel,
  Snackbar,
  Toolbar,
} from '@lib/ui-components';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthApiService } from '../../../../services/auth.api.service';
import { RolesEnum } from '../../../../domain/enums/roles.enum';
import { AuthoritiesEnum } from '../../../../domain/enums/authorities.enum';
import { DeleteUserButton } from '../../../technical-admin/organization-users/delete-user-button/delete-user-button';
import { useStateIfMounted } from 'use-state-if-mounted';
import { EditUserButton } from '../../../technical-admin/organization-users/edit-user-button/edit-user-button';
import { StyledMarginDiv } from '@shared/general';
import { UserAuthoritiesService } from '../../../../domain/utils/user-authorities.service';
import i18n from 'i18next';
import { AssignProjectsButton } from '../../../technical-admin/organization-users/assign-projects-button/assign-projects-button';
import { AssignReportsButton } from '../../../technical-admin/organization-users/assign-reports-button/assign-reports-button';
import { useUserState } from '../../../../state/UserState';

interface IFormInput {
  name: string;
  email: string;
  login: string;
  authorities: string[];
}

const defaultValues = {
  name: '',
  email: '',
  login: '',
  authorities: [],
};

export const OrganizationUser = () => {
  const { t } = useTranslation(); // needed to react to language change (even if not used directly)
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const errorKeys = translations.error;
  const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
  const [failureMessage, setFailureMessage] = useStateIfMounted('');
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const userState = useUserState();
  const isOrganizationAdmin = userState.roles.includes(RolesEnum.ORGANIZATION) && userState.authorities.includes(AuthoritiesEnum.ORGANIZATION_ADMIN);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('OrganizationUsers.addAccount.name.required')),
    email: Yup.string().required(t('OrganizationUsers.addAccount.email.required')).email(t('OrganizationUsers.addAccount.email.format')),
    login: Yup.string().required(t('OrganizationUsers.addAccount.login.required')),
  });

  const methods = useForm<IFormInput>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control, reset, setValue } = methods;

  const columns: Array<DataTableColumn> = [
    {
      key: 'name',
      label: t('name'),
      type: 'text',
    },
    {
      key: 'login',
      label: t('login'),
      type: 'text',
    },
    {
      key: 'email',
      label: t('email'),
      type: 'text',
    },
    {
      key: 'active',
      label: t('Model.User.active'),
      type: 'boolean',
      align: 'center',
    },
    {
      key: 'authorities',
      label: t('rights'),
      type: 'string-array-boolean-aggregate',
      enumValues: [
        { value: AuthoritiesEnum.ORGANIZATION_ADMIN, label: t('admin') },
        { value: AuthoritiesEnum.VIEWS_UNASSIGNED_PROJECTS, label: t('Model.User.viewsUnassignedProjects') },
        { value: AuthoritiesEnum.PROJECT_BDO, label: t('managingBdo') },
        { value: AuthoritiesEnum.PROJECT_INVOICE, label: t('invoicingPerson') },
        { value: AuthoritiesEnum.PROJECT_SALES, label: t('salesperson') },
        { value: AuthoritiesEnum.PROJECT_LEAD, label: t('projectLeadPerson') },
        { value: AuthoritiesEnum.SEES_PRICES, label: t('seesPrices') },
        {
          value: AuthoritiesEnum.DECISION_CHANGE_ACCEPTANCE_NOT_REQUIRED,
          label: t('decisionChangeAcceptanceNotRequired'),
        },
      ],
    },
  ];

  const rowOptions: DataTableRowOptionItem[] = [
    {
      renderer: (row) => (
        <EditUserButton
          id={row.id}
          name={row.name}
          login={row.login}
          email={row.email}
          authorities={row.authorities}
          buttonLabel={t('edit')}
          onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
        />
      ),
    },
    {
      renderer: (row) => <DeleteUserButton id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
    },
    {
      renderer: (row) => <AssignProjectsButton accountId={row.id} onSaved={async () => setTableReloadKey((prevState) => prevState + 1)} />,
    },
    {
      renderer: (row) => <AssignReportsButton accountId={row.id} accountName={row.name} onSaved={async () => setTableReloadKey((prevState) => prevState + 1)} />,
      isVisible: () => isOrganizationAdmin,
    },
  ];

  async function addAccountHandler() {
    setOpenAddUserDialog(true);
  }

  async function handleCloseAddUserDialog() {
    setOpenAddUserDialog(false);
    reset();
  }

  async function handleSaveAddUserDialog(data: IFormInput) {
    const result = await AuthApiService.createUser({
      name: data.name,
      email: data.email?.trim(),
      login: data.login?.trim(),
      roles: [RolesEnum.ORGANIZATION],
      authorities: UserAuthoritiesService.extractAuthorities(data.authorities),
      password: 'xxxx',
      tags: new Map<string, string>(),
    });
    if ('httpStatus' in result) {
      setFailureMessage(errorKeys[result.code] || t('Common.error'));
    } else {
      setTableReloadKey(tableReloadKey + 1); // reload table
      setOpenAddUserDialog(false);
      reset();
    }
  }

  return (
    <>
      <Toolbar right={[<Button label={t('OrganizationUsers.addAccount')} onClick={async () => addAccountHandler()} startIcon={<AddIcon />} />]} />
      <DataTable
        refreshKey={tableReloadKey}
        fetchFilters={{ role: RolesEnum.ORGANIZATION }}
        modelDef={{ modelName: 'accounts', search: 'byRole' }}
        columns={columns}
        rowOptions={rowOptions}
      />

      <Dialog open={openAddUserDialog} onClose={() => handleCloseAddUserDialog()} maxWidth='md' fullWidth={true}>
        <StyledMarginDiv top='2rem' right='auto' left='auto'>
          <LargeAddIcon />
        </StyledMarginDiv>
        <DialogTitle align='center'>{t('OrganizationUsers.addAccount.dialogTitle')}</DialogTitle>
        <DialogContent>
          <StyledMarginDiv top='1rem'>
            <FormInputText name='name' control={control} label={t('name')} />
            <FormInputText name='login' control={control} label={t('login')} />
            <FormInputText name='email' control={control} label={t('email')} />
          </StyledMarginDiv>
          <FormInputCheckbox name='authorities.organizationAdmin' control={control} label={t('admin')} setValue={setValue} initialValue={false} />
          <FormInputCheckbox
            name='authorities.viewsUnassignedProjects'
            control={control}
            label={t('OrganizationUsers.viewsUnassignedProjects')}
            setValue={setValue}
            initialValue={false}
          />
          <FormInputCheckbox name='authorities.seesPrices' control={control} label={t('seesPrices')} setValue={setValue} initialValue={false} />
          <FormInputCheckbox
            name='authorities.decisionChangeAcceptanceNotRequired'
            control={control}
            label={t('decisionChangeAcceptanceNotRequired')}
            setValue={setValue}
            initialValue={false}
          />
          <Panel borderType='flat' header={t('projects')}>
            <FormInputCheckbox name='authorities.projectBdo' control={control} label={t('managingBdo')} setValue={setValue} initialValue={false} />
            <FormInputCheckbox name='authorities.projectInvoice' control={control} label={t('invoicingPerson')} setValue={setValue} initialValue={false} />
            <FormInputCheckbox name='authorities.projectSales' control={control} label={t('salesperson')} setValue={setValue} initialValue={false} />
            <FormInputCheckbox name='authorities.projectLeadPerson' control={control} label={t('projectLeadPerson')} setValue={setValue} initialValue={false} />
          </Panel>
        </DialogContent>
        <DialogActions sx={{ margin: '1rem auto' }}>
          <Button label={t('Common.cancel')} onClick={() => handleCloseAddUserDialog()} />
          <Button label={t('save')} color='primary' variant='contained' onClick={async () => handleSubmit(handleSaveAddUserDialog)()} />
        </DialogActions>
      </Dialog>
      <Snackbar message={failureMessage} severity='error' onClose={() => setFailureMessage('')} />
    </>
  );
};
