import { Expose } from 'class-transformer';
import { BaseModel } from '@lib/common-sdk';

export type SubcontractorType = 'TRANSPORT' | 'TAKEOVER' | 'RECEIVER' | 'OTHER' | 'RECEIVERCOUNCILWASTES';

export enum SubcontractorTypesEnum {
  TRANSPORTING = 'TRANSPORTING',
  RECEIVING = 'RECEIVING',
  TAKING_OVER = 'TAKING_OVER',
  OTHER = 'OTHER',
  RECEIVING_COMMUNAL_WASTE = 'RECEIVING_COMMUNAL_WASTE',
}

export class CompanyViewModel extends BaseModel {
  @Expose()
  companyId?: string;
  @Expose()
  active?: boolean;
  @Expose()
  name?: string;
  @Expose()
  shortName?: string;
  @Expose()
  nip?: string;
  @Expose()
  krs?: string;
  @Expose()
  bdoEupId?: string;
  @Expose()
  bdoCompanyId?: string;
  @Expose()
  bdoIdentificationNumber?: string;
  @Expose()
  mpdNumber?: string;
  @Expose()
  buyerId?: string;
  @Expose()
  verifiedBdo?: boolean;
  @Expose()
  bdoNotApplicable?: boolean;
  @Expose()
  collectionDecisionNotRequired?: boolean;
  @Expose()
  roleClient?: boolean;
  @Expose()
  roleContractor?: boolean;
  @Expose()
  contractorType?: SubcontractorType;
  @Expose()
  invoiceType: 'PERMONTH' | 'PERCOLLECTION' | undefined;
  @Expose()
  digitalInvoiceConsent?: boolean;
  @Expose()
  correspondenceAddress?: CompanyAddress;
  @Expose()
  registrationAddress?: CompanyAddress;
  @Expose()
  companySettings?: CompanySettings;
  @Expose()
  subcontractorTypes?: Array<SubcontractorTypesEnum>;
  @Expose()
  createdBy?: string;
  @Expose()
  createdAt?: string;
  @Expose()
  organizationContact?: string;
  @Expose()
  organizationContactEmail?: string;
  @Expose()
  lastModifiedBy?: string;
  @Expose()
  lastModifiedDate?: string;
  @Expose()
  lastModifiedClientAgreementsBy?: string;
  @Expose()
  lastModifiedClientAgreementsDate?: string;
  @Expose()
  lastModifiedSubcontractorAgreementsBy?: string;
  @Expose()
  lastModifiedSubcontractorAgreementsDate?: string;

  get isEupSet(): boolean {
    return this.bdoEupId !== undefined;
  }
}

export class CompanyAddress {
  constructor(registrationAddress: CompanyAddress | undefined) {
    if (registrationAddress) {
      this.town = registrationAddress.town;
      this.province = registrationAddress.province;
      this.commune = registrationAddress.commune;
      this.communeId = registrationAddress.communeId;
      this.country = registrationAddress.country;
      this.street = registrationAddress.street;
      this.postalCode = registrationAddress.postalCode;
      this.houseNum = registrationAddress.houseNum;
      this.apartmentNum = registrationAddress.apartmentNum;
    }
  }

  @Expose()
  town?: string; // miejscowość
  @Expose()
  province?: string; // województwo
  @Expose()
  commune?: string; // gmina
  @Expose()
  communeId?: string; // id gminy (spring data)
  @Expose()
  country?: string; // kraj

  @Expose()
  street?: string;
  @Expose()
  postalCode?: string;
  @Expose()
  houseNum?: string;
  @Expose()
  apartmentNum?: string;

  get addressAsString(): string {
    return `${this.street ?? ''} ${this.houseNum ?? ''}${this.apartmentNum ? '/' + this.apartmentNum : ''}, ${this.postalCode ?? ''} ${this.town ?? ''}`;
  }
}

export class ContactPerson {
  @Expose()
  firstName?: string;
  @Expose()
  lastName?: string;
  @Expose()
  email?: string;
  @Expose()
  phone?: string;
  @Expose()
  comment?: string;
  @Expose()
  mainContactPerson?: boolean;
  @Expose()
  projects?: string[] = [];
  @Expose()
  type?: 'CONTACT' | 'EINVOICE' | 'REPORT' | 'INTERSEROH' | 'ORDER';
}

export class CompanySettings {
  @Expose()
  keoOnlyFromPlatform = false;
  @Expose()
  keoTurnedOnInBdo = false;
  @Expose()
  bdoRegisterForCurrentYearOnly = false;
  @Expose()
  withdrawKpoCardAutomatically = false;
}

export class CompanyContactPerson {
  @Expose()
  contactPersonId?: string;
  @Expose()
  firstName?: string;
  @Expose()
  lastName?: string;
  @Expose()
  email?: string;
  @Expose()
  phone?: string;
  @Expose()
  comment?: string;
}
