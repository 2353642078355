import { BaseModel } from '@lib/common-sdk';
import { Expose } from 'class-transformer';
import { CompanyAddress } from '../company/company.model';
import { ProjectBranchInfrastructureServiceViewModel } from '../project/project-branch-infrastructure-service-view.model';
import { ServicePrice } from './service-price';

export enum SettlementViewType {
  SERVICE = 'SERVICE',
  INFRASTRUCTURE_SERVICE = 'INFRASTRUCTURE_SERVICE',
  COMPLAINT = 'COMPLAINT',
}

export enum SettlementViewStatus {
  SETTLED = 'SETTLED',
  UNSETTLED = 'UNSETTLED',
  TO_BE_ACCEPTED = 'TO_BE_ACCEPTED',
  INVOICED = 'INVOICED',
  CANCELLED = 'CANCELLED',
}

export enum InvoiceBearer {
  CLIENT_TRANSPORT = 'CLIENT_TRANSPORT',
  SUBCONTRACTOR_TRANSPORT = 'SUBCONTRACTOR_TRANSPORT',
  CLIENT_COLLECTION = 'CLIENT_COLLECTION',
  SUBCONTRACTOR_COLLECTION = 'SUBCONTRACTOR_COLLECTION',
  CLIENT = 'CLIENT',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
}

export class ComplaintData {
  complaintSettlementId?: string;
  mass?: number;
  quantities?: number;
  priceInfos?: { [key: string]: ServicePrice };
}

export class SettlementDetailsView extends BaseModel {
  @Expose()
  settlementId?: string;
  @Expose()
  settings?: any;
  @Expose()
  yearMonth?: string;
  @Expose()
  projectName?: string;
  @Expose()
  projectNumber?: string;
  @Expose()
  companyName?: string;
  @Expose()
  branchName?: string;
  @Expose()
  serviceId?: string;
  @Expose()
  kpoNumber?: string;
  @Expose()
  kpoStatus?: string;
  @Expose()
  serviceType?: string;
  @Expose()
  infrastructureServiceId?: string;
  @Expose()
  settlementType?: SettlementViewType;
  @Expose()
  settlementStatus?: SettlementViewStatus;
  @Expose()
  serviceView?: any;
  @Expose()
  bdoFinalMassNotMatchedWithReceivedMass?: boolean;
  @Expose()
  estimatedMass?: number;
  @Expose()
  reportedMass?: number;
  @Expose()
  receivedMass?: number;
  @Expose()
  exceedMass?: number;
  @Expose()
  estimatedQuantities?: number;
  @Expose()
  reportedQuantities?: number;
  @Expose()
  receivedQuantities?: number;
  @Expose()
  exceedContainers?: number;
  @Expose()
  receivedVolume?: number;
  @Expose()
  estimatedTransports?: number;
  @Expose()
  completedTransports?: number;
  @Expose()
  reportedTransports?: number;
  @Expose()
  reportDate?: string;
  @Expose()
  requestDate?: string;
  @Expose()
  implementationDate?: string;
  @Expose()
  massDiscrepancy?: number;
  @Expose()
  massDiscrepancyInRange?: boolean;
  @Expose()
  registrationAddress?: CompanyAddress;
  @Expose()
  infrastructureServiceQuantities?: number;
  @Expose()
  infrastructureService?: ProjectBranchInfrastructureServiceViewModel;

  @Expose()
  complaintData?: ComplaintData;
  @Expose()
  complaintSettlementView?: SettlementDetailsView;
  @Expose()
  hasComplaint?: boolean;

  @Expose()
  pixDataVO?: any;
  @Expose()
  pixPrice?: ServicePrice;

  @Expose()
  clientCollectionNavResourceNumber?: string;
  @Expose()
  subcontractorCollectionNavResourceNumber?: string;
  @Expose()
  clientCollectionNavResourceName?: string;
  @Expose()
  subcontractorCollectionNavResourceName?: string;

  @Expose()
  lastModifiedBy?: string;
  @Expose()
  lastModifiedByEmail?: string;
  @Expose()
  lastModifiedDate?: string;
}
