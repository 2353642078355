import { EditModelButton, GridLabel, Panel, Tabs, TextLabel } from '@lib/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AssignedProjectsToEntityTypes, CompanyBranchAssignedProjects } from './company-branch-assigned-projects';
import { TitlePositioner } from '@shared/general';
import { CompanyBranchGeneral } from './company-branch-general';
import { CompanyBranchContact } from './company-branch-contact/company-branch-contact';
import { CompanyBranchLoginAccounts } from './company-branch-login-accounts';
import { AggregateCommandClientService, DataResultsDto } from '@lib/common-sdk';
import { useCompanyBranchView } from '@data-source-hooks';
import { QueryResultRenderer } from '../../../../../../../../libs/ui-components/components/query-result-renderer';
import * as Yup from 'yup';

interface CompanyBranchDataProps {
  companyBranchId: string;
}

function isCorrespondenceAddressVisible(data: any): boolean {
  return data['correspondenceAddressEqRegistrationAddress'] !== true;
}

export const CompanyBranchData = (props: CompanyBranchDataProps) => {
  const { t } = useTranslation();
  const [branchQuery, refreshBranchQuery] = useCompanyBranchView(props.companyBranchId);

  return (
    <QueryResultRenderer
      query={branchQuery}
      render={(companyBranchView) => {
        const registrationAddress = companyBranchView.registrationAddress!;
        const correspondenceAddress = companyBranchView.correspondenceAddress!;

        return (
          <>
            <Panel direction='row' borderType='flat'>
              <Panel flex='1'>
                <TitlePositioner>
                  <TextLabel label={t('registrationAddress').toUpperCase()} />
                </TitlePositioner>
                <Panel borderType='raised'>
                  <GridLabel gridTemplateColumns='8fr 1fr'>
                    <GridLabel gridTemplateColumns='1fr 1fr 1fr 1fr' gap='1rem'>
                      <TextLabel label={t('street')} value={registrationAddress.street} direction={'column'} />
                      <TextLabel label={t('houseNum')} value={registrationAddress.houseNum} direction={'column'} />
                      <TextLabel label={t('apartmentNum')} value={registrationAddress.apartmentNum} direction={'column'} />
                      <TextLabel label={t('locality')} value={registrationAddress.town} direction={'column'} />
                      <TextLabel label={t('postcode')} value={registrationAddress.postalCode} direction={'column'} />
                      <TextLabel label={t('commune')} value={registrationAddress.commune} direction={'column'} />
                      <TextLabel label={t('province')} value={registrationAddress.province} direction={'column'} />
                      <TextLabel label={t('country')} value={registrationAddress.country} direction={'column'} />
                    </GridLabel>
                    <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
                      <EditModelButton
                        testId={'registration-address'}
                        entity={companyBranchView}
                        id={0}
                        modelName={''}
                        dialogTitle={t('registrationAddress').toUpperCase()}
                        fields={[
                          {
                            rowItems: [
                              {
                                field: 'registrationAddress.street',
                                label: t('street'),
                                type: 'text',
                              },
                              {
                                field: 'registrationAddress.houseNum',
                                label: t('houseNum'),
                                type: 'text',
                                width: '20rem',
                              },
                              {
                                field: 'registrationAddress.apartmentNum',
                                label: t('apartmentNum'),
                                type: 'text',
                                width: '20rem',
                              },
                            ],
                          },
                          {
                            rowItems: [
                              {
                                field: 'registrationAddress.town',
                                label: t('locality'),
                                type: 'text',
                                width: '15rem',
                              },
                              {
                                field: 'registrationAddress.postalCode',
                                label: t('postcode'),
                                type: 'text',
                                width: '21.5rem',
                              },
                            ],
                          },
                          {
                            rowItems: [
                              {
                                label: t('commune'),
                                type: 'table-select',
                                field: 'registrationAddress.communeId',
                                tableSelectParams: {
                                  modelName: 'communes',
                                  dialogTitle: t('communes'),
                                  displayFormat: '{name}',
                                  columns: [
                                    {
                                      key: 'name',
                                      label: t('commune'),
                                      type: 'text',
                                    },
                                    {
                                      key: 'province',
                                      label: t('province'),
                                      type: 'text',
                                    },
                                  ],
                                  otherValuesMap: {
                                    name: 'registrationAddress.commune',
                                    province: 'registrationAddress.province',
                                  },
                                },
                              },
                              {
                                field: 'registrationAddress.country',
                                label: t('country'),
                                type: 'text',
                              },
                            ],
                          },
                        ]}
                        validationSchema={{
                          registrationAddress: Yup.object({
                            street: Yup.string().required(t('requiredField')),
                            postalCode: Yup.string().required(t('requiredField')),
                            houseNum: Yup.string().required(t('requiredField')),
                            town: Yup.string().required(t('requiredField')),
                            communeId: Yup.string().required(t('requiredField')),
                          }),
                        }}
                        onBeforeSave={(formData) => {
                          const registrationCommuneIdParts = formData.registrationAddress.communeId.split('/');
                          formData.registrationAddress!.communeId = registrationCommuneIdParts[registrationCommuneIdParts.length - 1];

                          formData.companyId = companyBranchView.companyId;
                          formData.branchId = companyBranchView.branchId;
                          formData.name = companyBranchView.name;
                          formData.shortName = companyBranchView.shortName;
                          formData.correspondenceAddress = companyBranchView.correspondenceAddress;
                          return formData;
                        }}
                        overrideSaveCall={async (payload: any) => {
                          try {
                            await AggregateCommandClientService.callCommand<any>('CompanyAggregate', 'updateCompanyBranch', payload.companyId, payload);
                            return new DataResultsDto(false, 200, undefined, undefined, undefined);
                          } catch (error: any) {
                            return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                          }
                        }}
                        onSaved={async (result) => {
                          await refreshBranchQuery();
                        }}
                      />
                    </GridLabel>
                  </GridLabel>
                </Panel>
              </Panel>
              <Panel flex='1'>
                <TitlePositioner>
                  <TextLabel label={t('correspondenceAddress').toUpperCase()} />
                </TitlePositioner>
                <Panel borderType='raised'>
                  <GridLabel gridTemplateColumns='8fr 1fr'>
                    <GridLabel gridTemplateColumns='1fr 1fr 1fr 1fr' gap='1rem'>
                      <TextLabel label={t('street')} value={correspondenceAddress.street} direction={'column'} />
                      <TextLabel label={t('houseNum')} value={correspondenceAddress.houseNum} direction={'column'} />
                      <TextLabel label={t('apartmentNum')} value={correspondenceAddress.apartmentNum} direction={'column'} />
                      <TextLabel label={t('locality')} value={correspondenceAddress.town} direction={'column'} />
                      <TextLabel label={t('postcode')} value={correspondenceAddress.postalCode} direction={'column'} />
                      <TextLabel label={t('commune')} value={correspondenceAddress.commune} direction={'column'} />
                      <TextLabel label={t('province')} value={correspondenceAddress.province} direction={'column'} />
                      <TextLabel label={t('country')} value={correspondenceAddress.country} direction={'column'} />
                    </GridLabel>
                    <GridLabel gridTemplateColumns='none' justifyContent='flex-end'>
                      <EditModelButton
                        testId={'correspondence-address'}
                        entity={companyBranchView}
                        id={0}
                        modelName={''}
                        dialogTitle={t('correspondenceAddress').toUpperCase()}
                        fields={[
                          {
                            label: t('correspondenceAddressEqRegistrationAddress'),
                            type: 'boolean',
                            field: 'correspondenceAddressEqRegistrationAddress',
                          },
                          {
                            rowItems: [
                              {
                                field: 'correspondenceAddress.street',
                                label: t('street'),
                                type: 'text',
                                isVisible: (data) => isCorrespondenceAddressVisible(data),
                              },
                              {
                                field: 'correspondenceAddress.houseNum',
                                label: t('houseNum'),
                                type: 'text',
                                isVisible: (data) => isCorrespondenceAddressVisible(data),
                                width: '20rem',
                              },
                              {
                                field: 'correspondenceAddress.apartmentNum',
                                label: t('apartmentNum'),
                                type: 'text',
                                isVisible: (data) => isCorrespondenceAddressVisible(data),
                                width: '20rem',
                              },
                            ],
                          },
                          {
                            rowItems: [
                              {
                                field: 'correspondenceAddress.town',
                                label: t('locality'),
                                type: 'text',
                                isVisible: (data) => isCorrespondenceAddressVisible(data),
                                width: '15rem',
                              },
                              {
                                field: 'correspondenceAddress.postalCode',
                                label: t('postcode'),
                                type: 'text',
                                isVisible: (data) => isCorrespondenceAddressVisible(data),
                                width: '21.5rem',
                              },
                            ],
                          },
                          {
                            rowItems: [
                              {
                                label: t('commune'),
                                type: 'table-select',
                                field: 'correspondenceAddress.communeId',
                                isVisible: (data) => isCorrespondenceAddressVisible(data),
                                tableSelectParams: {
                                  modelName: 'communes',
                                  dialogTitle: t('communes'),
                                  displayFormat: '{name}',
                                  columns: [
                                    {
                                      key: 'name',
                                      label: t('commune'),
                                      type: 'text',
                                    },
                                    {
                                      key: 'province',
                                      label: t('province'),
                                      type: 'text',
                                    },
                                  ],
                                  otherValuesMap: {
                                    name: 'correspondenceAddress.commune',
                                    province: 'correspondenceAddress.province',
                                  },
                                },
                              },
                              {
                                field: 'correspondenceAddress.country',
                                label: t('country'),
                                type: 'text',
                                isVisible: (data) => isCorrespondenceAddressVisible(data),
                              },
                            ],
                          },
                        ]}
                        validationSchema={{
                          correspondenceAddressEqRegistrationAddress: Yup.boolean(),
                          correspondenceAddress: Yup.object().when('correspondenceAddressEqRegistrationAddress', {
                            is: (correspondenceAddressEqRegistrationAddress: boolean) => !correspondenceAddressEqRegistrationAddress,
                            then: Yup.object({
                              street: Yup.string().required(t('requiredField')),
                              postalCode: Yup.string().required(t('requiredField')),
                              houseNum: Yup.string().required(t('requiredField')),
                              town: Yup.string().required(t('requiredField')),
                              communeId: Yup.string().required(t('requiredField')),
                            }),
                          }),
                        }}
                        onBeforeSave={(formData) => {
                          formData.companyId = companyBranchView.companyId;
                          formData.branchId = companyBranchView.branchId;
                          formData.name = companyBranchView.name;
                          formData.shortName = companyBranchView.shortName;
                          formData.registrationAddress = companyBranchView.registrationAddress;
                          if (formData.correspondenceAddressEqRegistrationAddress === true) {
                            formData.correspondenceAddress = companyBranchView.registrationAddress;
                          } else {
                            const correspondenceCommuneIdParts = formData.correspondenceAddress.communeId.split('/');
                            formData.correspondenceAddress!.communeId = correspondenceCommuneIdParts[correspondenceCommuneIdParts.length - 1];
                          }
                          return formData;
                        }}
                        overrideSaveCall={async (payload: any) => {
                          try {
                            await AggregateCommandClientService.callCommand<any>('CompanyAggregate', 'updateCompanyBranch', payload.companyId, payload);
                            return new DataResultsDto(false, 200, undefined, undefined, undefined);
                          } catch (error: any) {
                            return new DataResultsDto(true, error.status, undefined, undefined, undefined);
                          }
                        }}
                        onSaved={async (result) => {
                          await refreshBranchQuery();
                        }}
                      />
                    </GridLabel>
                  </GridLabel>
                </Panel>
              </Panel>
            </Panel>
            <Tabs
              borderType='none'
              panels={[
                {
                  label: t('assignedProjects'),
                  component: (
                    <CompanyBranchAssignedProjects
                      entityType={AssignedProjectsToEntityTypes.BRANCH}
                      entityId={companyBranchView.branchId!}
                      roleClient={companyBranchView.roleClient === true}
                      roleContractor={companyBranchView.roleContractor === true}
                    />
                  ),
                },
                {
                  label: t('general'),
                  component: <CompanyBranchGeneral branchView={companyBranchView} refreshBranch={refreshBranchQuery} />,
                },
                {
                  label: t('contact'),
                  component: <CompanyBranchContact />,
                },
                {
                  label: t('userAccounts'),
                  component: <CompanyBranchLoginAccounts />,
                },
              ]}
            />
          </>
        );
      }}
    />
  );
};
