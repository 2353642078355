import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { CompanyAddress, CompanyRole, SubcontractorTypesEnum } from '@lib/api-interface';
import { ToggleButtons } from '@lib/ui-components';
import { useTranslation } from 'react-i18next';
import { DataSearchField } from '../../../libs/ui-components/components/data-table/table-search-field/data-search-field';
import { getMarkerIcon, MarkerIconColor } from './marker';
import axios from 'axios';

export interface Location {
  id: number;
  name?: string;
  companyName?: string;
  latitude: number;
  longitude: number;
  central?: boolean;
  nip?: string;
  registrationAddress?: CompanyAddress;
  address?: string;
  companyId?: string;
  branchId?: string;
  roleContractor: boolean;
  roleClient: boolean;
  subcontractorTypes: SubcontractorTypesEnum[];
}

interface MapProps {
  companyRole: CompanyRole;
}

export const Map = (props: MapProps) => {
  const { t } = useTranslation();
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [filteredLocations, setFilteredLocations] = React.useState<Location[]>([]);
  const [activeClientTabIndex, setActiveClientTabIndex] = React.useState<number>(0);
  const [activeSubcontractorTabIndex, setActiveSubcontractorTabIndex] = React.useState<number>(0);
  const [searchField, setSearchField] = React.useState<string>('');

  const fetchLocations = React.useCallback(async () => {
    try {
      const response = await axios.get(`${process.env['REACT_APP_API_URL']}/api/maps`);
      const data = response.data.map((loc: any) => ({
        ...loc,
        address: loc.registrationAddress ? new CompanyAddress(loc.registrationAddress).addressAsString : '',
      }));
      setLocations(data);
      setFilteredLocations(data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, []);

  const filterLocationsByRole = React.useCallback(
    (locs: Location[]) => {
      if (props.companyRole === CompanyRole.CLIENT) {
        const clientLocs = locs.filter((loc) => loc.roleClient);
        return activeClientTabIndex === 1 ? clientLocs.filter((loc) => loc.central) : clientLocs;
      }

      const contractorLocs = locs.filter((loc) => loc.roleContractor);
      switch (activeSubcontractorTabIndex) {
        case 1:
          return contractorLocs.filter((loc) => loc.subcontractorTypes.includes(SubcontractorTypesEnum.TAKING_OVER));
        case 2:
          return contractorLocs.filter((loc) => loc.subcontractorTypes.includes(SubcontractorTypesEnum.TRANSPORTING));
        default:
          return contractorLocs;
      }
    },
    [props.companyRole, activeClientTabIndex, activeSubcontractorTabIndex],
  );

  const searchLocations = React.useCallback(
    (searchTerm: string) => {
      if (!searchTerm.trim()) {
        return filterLocationsByRole(locations);
      }

      const searchLower = searchTerm.toLowerCase().trim();
      return filterLocationsByRole(locations).filter(
        (location) =>
          location.name?.toLowerCase().includes(searchLower) ||
          location.companyName?.toLowerCase().includes(searchLower) ||
          location.nip?.includes(searchLower) ||
          location.address?.toLowerCase().includes(searchLower),
      );
    },
    [locations, filterLocationsByRole, props.companyRole],
  );

  const LocationMarker = React.useMemo(() => {
    function LocationMarker({ location }: { location: Location }) {
      const renderPopupContent = () => {
        return props.companyRole === CompanyRole.CLIENT ? (
          <>
            <b>{`${location.companyName} ${location.name}`}</b>
            <br />
            {location.nip}
            <br />
            {location.address}
          </>
        ) : (
          <>
            <b>
              <a onClick={() => window.open(`/companies/!${location.companyId}/!${location.branchId}`, '_blank')} style={{ cursor: 'pointer' }}>
                {`${location.companyName} ${location.name}`}
              </a>
            </b>
            <br />
            {location.nip}
            <br />
            {location.address}
          </>
        );
      };

      return (
        <Marker key={location.id} position={[location.latitude, location.longitude]} icon={getMarkerIcon(location.central ? MarkerIconColor.RED : MarkerIconColor.YELLOW)}>
          <Popup>{renderPopupContent()}</Popup>
        </Marker>
      );
    }

    return LocationMarker;
  }, [props.companyRole]);

  const SearchBar = React.useMemo(() => {
    function SearchBar({ onSearch }: { onSearch: (value: string) => void }) {
      return (
        <div style={{ position: 'absolute', right: 0 }}>
          <DataSearchField onFiltersChange={onSearch} />
        </div>
      );
    }

    return SearchBar;
  }, []);

  const toggleButtons = React.useMemo(() => {
    if (props.companyRole === CompanyRole.CLIENT) {
      return (
        <ToggleButtons
          labels={[t('Maps.clients.clientsAndBranches'), t('Maps.clients.headquarters')]}
          activeIndex={activeClientTabIndex}
          onChange={setActiveClientTabIndex}
          withUseEffect={true}
        />
      );
    }
    return (
      <ToggleButtons
        labels={[t('Maps.subcontractors.collectingAndTransportingSubjects'), t('Maps.subcontractors.collectingSubjects'), t('Maps.subcontractors.transportingSubjects')]}
        activeIndex={activeSubcontractorTabIndex}
        onChange={setActiveSubcontractorTabIndex}
        withUseEffect={true}
      />
    );
  }, [props.companyRole, activeClientTabIndex, activeSubcontractorTabIndex, t]);

  const clusterOptions = React.useMemo(
    () => ({
      chunkedLoading: true,
      maxClusterRadius: 50,
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      removeOutsideVisibleBounds: true,
    }),
    [],
  );

  const markers = React.useMemo(
    () => (
      <MarkerClusterGroup {...clusterOptions}>
        {filteredLocations.map((location) => (
          <LocationMarker key={location.id} location={location} />
        ))}
      </MarkerClusterGroup>
    ),
    [filteredLocations, clusterOptions],
  );

  const map = React.useMemo(
    () => (
      <MapContainer center={[52.22977, 21.01178]} zoom={7} style={{ height: 'calc(100vh - 180px)', zIndex: 0 }}>
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&amp;copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors |
        &amp;copy; <a href="https://opencagedata.com/">OpenCage</a> geocoding |
        <a href="https://github.com/pointhi/leaflet-color-markers?tab=readme-ov-file/">Markers</a> images'
          detectRetina={true}
        />
        {markers}
      </MapContainer>
    ),
    [markers],
  );

  React.useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  React.useEffect(() => {
    setFilteredLocations(searchLocations(searchField));
  }, [searchField, props.companyRole, activeClientTabIndex, activeSubcontractorTabIndex]);

  const handleSearch = React.useCallback((filter: string) => {
    setSearchField(filter);
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          margin: '0.5rem',
          alignItems: 'center',
        }}
      >
        {toggleButtons}
        <SearchBar onSearch={handleSearch} />
      </div>
      {map}
    </>
  );
};
