import React from 'react';
import { Panel } from '@lib/ui-components';
import { CompanyViewModel } from '@lib/api-interface';
import {
  AssignedProjectsToEntityTypes,
  CompanyBranchAssignedProjects,
} from '../../shared/company-branches/company-branch-view/company-branch-data/company-branch-assigned-projects';

export interface ContractorAssignedProjectsProps {
  company: CompanyViewModel;
}

export const ContractorAssignedProjects = (props: ContractorAssignedProjectsProps) => {
  return (
    <Panel borderType='raised'>
      <CompanyBranchAssignedProjects
        entityType={AssignedProjectsToEntityTypes.COMPANY}
        entityId={props.company.companyId!}
        roleContractor={props.company.roleContractor === true}
      />
    </Panel>
  );
};
