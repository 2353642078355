import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, DataTable, DataTableColumn, Header, Panel } from '@lib/ui-components';
import React from 'react';
import { ServicePriceBearer, ServicePriceType, ServiceType } from '@lib/api-interface';
import { CommonServicePickupDialog } from './common-service-pickup-dialog';
import { CollectionRequestStatus } from '../../../libs/api-interface/models/collection-request/collection-request-status.enum';
import { useProjectBranchSettingsContext } from '@context';
import { useUserState } from '../../../state/UserState';
import { RolesEnum } from '../../../domain/enums/roles.enum';
import { AuthoritiesEnum } from '../../../domain/enums/authorities.enum';

interface CommonPickupsProps {
  serviceType: ServiceType;
  projectId: string;
  branchId: string;
}

export const CommonPickups = (props: CommonPickupsProps) => {
  const userState = useUserState();
  const { t } = useTranslation();
  const projectSettings = useProjectBranchSettingsContext();
  const [upcomingTableReloadKey, setUpcomingTableReloadKey] = React.useState(0);
  const [inRealizationTableReloadKey, setInRealizationTableReloadKey] = React.useState(0);
  const clientUser = userState.roles.includes(RolesEnum.CLIENT_USER);
  const clientAdmin = userState.roles.includes(RolesEnum.CLIENT_ADMIN);
  const clientUserSeesPrices = clientUser && userState.authorities.includes(AuthoritiesEnum.SEES_PRICES);

  function reloadTables() {
    setUpcomingTableReloadKey(upcomingTableReloadKey + 1);
    setInRealizationTableReloadKey(inRealizationTableReloadKey + 1);
  }

  const upcomingTableColumns: DataTableColumn[] = React.useMemo(() => {
    let columns: DataTableColumn[] = [];
    switch (props.serviceType) {
      case ServiceType.PER_REQUEST:
        columns = [
          { key: 'projectWaste.wasteCode', label: t('wasteCode'), type: 'text' },
          { key: 'projectWaste.wasteDescription', label: t('wasteName'), type: 'text' },
          { key: 'projectWaste.projectWasteDescription', label: t('wasteDescription'), type: 'text' },
          { key: 'applicableCollectionInfrastructureCount', label: t('receiptSizeUnit'), type: 'numeric' },
          { key: 'applicableCollectionInfrastructureService.name', label: t('container'), type: 'text' },
          { key: 'recipient', label: t('recipient'), type: 'text' },
        ];
        break;
      case ServiceType.ADDITIONAL:
        columns = [
          {
            key: 'navResourceName',
            label: t('serviceDesc'),
            type: 'text',
            joinedColumns: [{ key: 'description', label: t('serviceDesc'), type: 'text' }],
          },
          { key: 'recipient', label: t('recipient'), type: 'text' },
        ];
        break;
    }
    if (clientUserSeesPrices) {
      columns.push({
        key: 'collectionInfrastructureService.currentSettings.clientLease',
        label: t('containerPrice'),
        type: 'numeric',
      });
      columns.push({
        key: 'currentSettings.clientTransportPrice.stringValue',
        label: t('clientTransport'),
        type: 'text',
      });
      columns.push({
        key: 'currentSettings.clientCollectionPrice.bearer',
        label: t('revenueOrIS'),
        type: 'enum',
        customValueMappings: [
          { value: ServicePriceBearer.ORGANIZATION_REVENUE, label: t('cost') },
          { value: ServicePriceBearer.ORGANIZATION_COST, label: t('revenue') },
        ],
      });
      columns.push({
        key: 'currentSettings.clientCollectionPrice.stringValue',
        label: t('clientCollection'),
        type: 'text',
      });
      columns.push({
        key: 'currentSettings.clientCollectionPrice.priceType',
        label: t('priceType'),
        type: 'enum',
        customValueMappings: [
          { value: ServicePriceType.UNIT, label: t('priceType.UNIT') },
          { value: ServicePriceType.M3, label: t('priceType.M3') },
          { value: ServicePriceType.MG, label: t('priceType.MG') },
        ],
      });
    }
    return columns;
  }, [t, props]);

  const inRealizationTableColumns: DataTableColumn[] = React.useMemo(() => {
    switch (props.serviceType) {
      case ServiceType.PER_REQUEST:
        return [
          { key: 'collectionRequestView.wasteCode', label: t('wasteCode'), type: 'text' },
          { key: 'collectionRequestView.wasteName', label: t('wasteName'), type: 'text' },
          { key: 'collectionRequestView.wasteDescription', label: t('wasteDescription'), type: 'text' },
          { key: 'collectionRequestView.containers', label: t('receiptSizeUnit'), type: 'numeric' },
          { key: 'collectionRequestView.container', label: t('container'), type: 'text' },
          {
            key: 'collectionRequestView.reactionTime',
            label: t('reactionTimeForClient'),
            type: 'text',
            renderer: (row) => t('upToXDays', { days: row.collectionRequestView.reactionTime }),
          },
          {
            key: 'collectionRequestView.creationDate',
            label: t('reportDate'),
            type: 'date-time',
            additionalElement: (row: any) => (row.collectionRequestView.status === CollectionRequestStatus.MISSED ? <div style={{ color: 'red' }}>{t('noPickup')}</div> : ''),
          },
          { key: 'recipient', label: t('recipient'), type: 'text' },
          { key: 'collectionRequestView.comment', label: t('comment'), type: 'text' },
        ];
      case ServiceType.ADDITIONAL:
        return [
          {
            key: 'collectionRequestView.reactionTime',
            label: t('reactionTimeForClient'),
            type: 'text',
            renderer: (row) => t('upToXDays', { days: row.collectionRequestView.reactionTime }),
          },
          { key: 'collectionRequestView.creationDate', label: t('reportDate'), type: 'date-time' },
          { key: 'serviceDescription', label: t('serviceDesc'), type: 'text' },
          { key: 'recipient', label: t('recipient'), type: 'text' },
          { key: 'collectionRequestView.comment', label: t('comment'), type: 'text' },
        ];
    }
    return [];
  }, [t, props]);

  return (
    <Panel borderType='none'>
      <Header label={t('upcomingPickupsDates')} />
      <DataTable
        refreshKey={upcomingTableReloadKey}
        fetchPostUrl={'api/client/collection-requests/services'}
        fetchFilters={{
          companyBranchId: props.branchId,
          projectId: props.projectId,
          serviceType: props.serviceType,
        }}
        columns={upcomingTableColumns}
        rowOptions={[
          {
            isVisible: () => clientAdmin || !!projectSettings?.collectionRequestActive,
            renderer: (row) => <CommonServicePickupDialog row={row} reload={() => reloadTables()} />,
          },
        ]}
      />
      <Header label={t('pickupsInProgress')} />
      <DataTable
        refreshKey={inRealizationTableReloadKey}
        fetchPostUrl={'api/client/collection-requests'}
        fetchFilters={{
          companyBranchId: props.branchId,
          projectId: props.projectId,
          serviceType: props.serviceType,
          collectionRequestStatuses: [
            CollectionRequestStatus.SCHEDULED_FOR_KPO_CREATION,
            CollectionRequestStatus.SCHEDULED_FOR_NOTIFICATION,
            CollectionRequestStatus.SCHEDULED,
            CollectionRequestStatus.IN_REALIZATION,
            CollectionRequestStatus.FEEDBACK_ALLOWED,
          ],
          shouldIncludeRecentlyMissed: true,
        }}
        columns={inRealizationTableColumns}
        rowOptions={[
          {
            isVisible: (row) => row.collectionRequestView.status === CollectionRequestStatus.FEEDBACK_ALLOWED,
            renderer: (row) => (
              <AggregateCommandButton
                aggregateName='CollectionRequestAggregate'
                aggregateId={row.collectionRequestView.collectionRequestId}
                commandName='reportMissedPickup'
                buttonLabel={t('reportMissedPickup')}
                color={'warning'}
                variant={'contained'}
                forceConfirmation={true}
                onSaved={async () => setInRealizationTableReloadKey(inRealizationTableReloadKey + 1)}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};
