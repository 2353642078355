import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, Panel } from '@lib/ui-components';
import { CompanyRole, ProjectType } from '@lib/api-interface';

export enum AssignedProjectsToEntityTypes {
  COMPANY = 'COMPANY',
  BRANCH = 'BRANCH',
}

export interface CompanyBranchDataProjectsProps {
  entityType: AssignedProjectsToEntityTypes;
  entityId: string;
  roleClient?: boolean;
  roleContractor?: boolean;
}

export const CompanyBranchAssignedProjects = (props: CompanyBranchDataProjectsProps) => {
  const { t } = useTranslation();

  return (
    <Panel borderType='flat'>
      <DataTable
        refreshKey={1}
        fetchPostUrl={'/api/organization/assigned-projects'}
        fetchFilters={{
          entityType: props.entityType,
          entityId: props.entityId,
          roles: [props.roleClient ? CompanyRole.CLIENT : undefined, props.roleContractor ? CompanyRole.SUBCONTRACTOR : undefined].filter(
            (role): role is CompanyRole => role !== undefined,
          ),
        }}
        columns={[
          {
            key: 'projectNumber',
            label: t('projectNum'),
            type: 'text-chip',
          },
          {
            key: 'name',
            label: t('projectName'),
            type: 'text',
            linkPath: '/projects/',
            linkRowId: 'project.projectId',
          },
          {
            key: 'sellerPerson',
            label: t('salesperson'),
            type: 'text',
          },
          {
            key: 'projectLeadPerson',
            label: t('projectLeadPerson'),
            type: 'text',
          },
          {
            key: 'invoicePerson',
            label: t('invoicingPerson'),
            type: 'text',
          },
          {
            key: 'managesBdoPerson',
            label: t('managingBdo'),
            type: 'text',
          },
          {
            key: 'type',
            label: t('projectType'),
            type: 'enum',
            enumValues: [
              { value: ProjectType.TRADING, label: t('Model.Project.type.TRADING') },
              { value: ProjectType.ONE_TIME, label: t('Model.Project.type.ONE_TIME') },
              { value: ProjectType.OTHER, label: t('Model.Project.type.OTHER') },
            ],
          },
          {
            key: 'creationDate',
            label: t('creationDate'),
            type: 'date',
          },
        ]}
      />
    </Panel>
  );
};
