import { TFunction } from 'react-i18next';
import { Stack } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import {
  GreyButton,
  priceInputFields,
  settlementNavResourceValidation,
} from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { AggregateCommandButton, GeneralFormItem, GeneralFormRowOfItems, TextLabel, UsecaseButton } from '@lib/ui-components';
import { MASS_PRECISION, PLN_PRECISION, QUANTITIES_PRECISION, ServicePriceType, ServiceSettings, ServiceType, SettlementDetailsView, VOLUME_PRECISION } from '@lib/api-interface';
import { DateTimeService } from '../../../../services/date-time.service';

export interface ServicesSettlementTableFooterProps {
  settlement: SettlementDetailsView;
  t: TFunction<'common'>;
  reloadSettlements: () => void;
}

export const ServicesSettlementTableFooter = ({ settlement, t, reloadSettlements }: ServicesSettlementTableFooterProps) => {
  const currentSettings: ServiceSettings = settlement.settings;
  const priceTypes: (ServicePriceType | undefined)[] = [
    settlement.pixPrice && settlement.settings.pix === true ? settlement.pixPrice : currentSettings.clientCollectionPrice,
    currentSettings.subcontractorCollectionPrice,
  ]
    .filter((servicePrice) => servicePrice?.price !== undefined)
    .map((servicePrice) => servicePrice?.priceType);
  const hasTransportPrices =
    [currentSettings.clientTransportPrice, currentSettings.subcontractorTransportPrice].filter((servicePrice) => servicePrice?.price !== undefined && servicePrice.price > 0)
      .length > 0;

  function accept() {
    return undefined; // TODO: implement
  }

  function discard() {
    return undefined; // TODO: implement
  }

  function uploadNoncomplianceProtocol() {
    return undefined; // TODO: implement
  }

  function servicePriceSection(
    header: string,
    field: 'clientCollectionPrice' | 'clientTransportPrice' | 'subcontractorCollectionPrice' | 'subcontractorTransportPrice' | 'communePrice',
  ): (GeneralFormItem | GeneralFormRowOfItems)[] {
    const value = currentSettings[field]?.price;
    return [
      {
        type: 'header',
        field: '',
        label: header,
      },
      {
        rowItems: [
          {
            label: t('oldPrice'),
            field: `placeholder.${field}`,
            type: 'numeric',
            defaultValue: value,
            numericPrecision: PLN_PRECISION,
            isDeactivated: true,
          },
          ...priceInputFields(field, t, {
            initialService: settlement.serviceView,
            defaultPrice: value,
            fixedPriceType: true,
            navResourceVisible: () => true,
          }),
        ],
      },
    ];
  }

  if (settlement.settlementStatus === 'INVOICED') {
    return <></>;
  }

  if (settlement.settlementStatus === 'CANCELLED') {
    return <GreyButton label={t('uploadNoncomplianceProtocol')} onClick={() => uploadNoncomplianceProtocol()} />;
  }

  return (
    <Stack direction={'row'} marginLeft={2} marginBottom={2} spacing={6} alignItems='center'>
      <AggregateCommandButton
        aggregateName='SettlementAggregate'
        aggregateId={settlement.settlementId!}
        commandName='transfer'
        buttonLabel={t('transferSettlement')}
        dialogTitle={t('selectSettlementMonth')}
        fields={[
          {
            label: t('month'),
            field: 'yearMonth',
            type: 'date-year-month',
            defaultValue: settlement.yearMonth,
            clearableDate: false,
          },
        ]}
        onBeforeSave={(formData) => {
          return {
            yearMonth: formData.yearMonth.substring(0, 7),
          };
        }}
        onSaved={async () => reloadSettlements()}
      />
      {settlement.settlementStatus === 'SETTLED' && (
        <UsecaseButton
          groupName='settlement'
          useCaseName='create-settlement-complaint'
          buttonLabel={t('complain')}
          maxWidth={false}
          fields={[
            {
              rowItems: [
                {
                  label: t('receivedMass'),
                  field: 'receivedMass',
                  type: 'numeric',
                  defaultValue: settlement.receivedMass,
                  numericPrecision: MASS_PRECISION,
                  isDeactivated: true,
                },
                {
                  label: t('complainedMass'),
                  field: 'mass',
                  type: 'numeric',
                  numericPrecision: MASS_PRECISION,
                },
              ],
            },
            {
              rowItems: [
                {
                  label: t('quantityReceived'),
                  field: 'receivedQuantities',
                  type: 'numeric',
                  numericPrecision: QUANTITIES_PRECISION,
                  defaultValue: settlement.receivedQuantities,
                  isDeactivated: true,
                },
                {
                  label: t('complainedQuantities'),
                  field: 'quantities',
                  type: 'numeric',
                },
              ],
            },
            ...servicePriceSection(t('clientTransport'), 'clientTransportPrice'),
            ...servicePriceSection(t('subcontractorTransport'), 'subcontractorTransportPrice'),
            ...servicePriceSection(t('clientCollection'), 'clientCollectionPrice'),
            ...servicePriceSection(t('subcontractorCollection'), 'subcontractorCollectionPrice'),
          ]}
          hiddenValues={{
            complaintSettlementId: settlement.settlementId,
          }}
          onBeforeSave={(formData) => {
            formData.priceInfos = {
              CLIENT_TRANSPORT: formData.clientTransportPrice,
              SUBCONTRACTOR_TRANSPORT: formData.subcontractorTransportPrice,
              CLIENT_COLLECTION: formData.clientCollectionPrice,
              SUBCONTRACTOR_COLLECTION: formData.subcontractorCollectionPrice,
            };
            delete formData.clientTransportPrice;
            delete formData.subcontractorTransportPrice;
            delete formData.clientCollectionPrice;
            delete formData.subcontractorCollectionPrice;
            delete formData.placeholder;
            return formData;
          }}
          validationSchema={settlementNavResourceValidation(t)}
          onSaved={async () => reloadSettlements()}
        />
      )}
      <AggregateCommandButton
        aggregateName='SettlementAggregate'
        aggregateId={settlement.settlementId!}
        commandName='cancel'
        buttonLabel={t('cancelSettlement')}
        forceConfirmation={true}
        onSaved={async () => reloadSettlements()}
      />
      {(settlement.settlementStatus === 'SETTLED' || settlement.settlementStatus === 'UNSETTLED') && (
        <UsecaseButton
          useCaseName='UpdateSettlementDeclarationUseCase'
          groupName='settlement'
          hiddenValues={{ settlementId: settlement.settlementId! }}
          buttonLabel={t('edit')}
          fields={[
            { label: t('kpoNumber'), field: 'kpoNumber', type: 'text', defaultValue: settlement.kpoNumber },
            {
              label: t('receivedMass'),
              field: 'receivedMass',
              type: 'numeric',
              numericPrecision: MASS_PRECISION,
              defaultValue: settlement.receivedMass,
              isVisible: () => settlement.serviceType != ServiceType.ADDITIONAL || priceTypes.includes(ServicePriceType.MG),
            },
            {
              label: t('quantityReceived'),
              field: 'receivedQuantities',
              type: 'numeric',
              numericPrecision: QUANTITIES_PRECISION,
              defaultValue: settlement.receivedQuantities,
              isVisible: () => priceTypes.includes(ServicePriceType.UNIT),
            },
            {
              label: t('volumeReceived'),
              field: 'receivedVolume',
              type: 'numeric',
              numericPrecision: VOLUME_PRECISION,
              defaultValue: settlement.receivedVolume,
              isVisible: () => priceTypes.includes(ServicePriceType.M3),
            },
            {
              label: t('transportCompleted'),
              field: 'reportedTransports',
              type: 'numeric',
              defaultValue: settlement.reportedTransports,
            },
            {
              label: t('implementationDate'),
              field: 'implementationDate',
              type: 'date',
              defaultValue: settlement.implementationDate,
              isVisible: () => settlement?.serviceView?.settlementType !== 'PER_MONTH',
            },
          ]}
          validationSchema={{
            receivedMass: Yup.string().when([], {
              is: () => settlement.serviceType !== ServiceType.ADDITIONAL || priceTypes.includes(ServicePriceType.MG),
              then: Yup.string().required(t('fieldRequired')),
            }),
            receivedQuantities: Yup.string().when([], {
              is: () => priceTypes.includes(ServicePriceType.UNIT),
              then: Yup.string().required(t('fieldRequired')),
            }),
            receivedVolume: Yup.string().when([], {
              is: () => priceTypes.includes(ServicePriceType.M3),
              then: Yup.string().required(t('fieldRequired')),
            }),
            reportedTransports: Yup.string().when([], {
              is: () => hasTransportPrices || settlement?.serviceView?.onDemand,
              then: Yup.string().required(t('fieldRequired')),
            }),
            implementationDate: Yup.string().when([], {
              is: () =>
                settlement?.serviceView?.settlementType === 'PER_COLLECTION' &&
                (settlement.serviceType === ServiceType.SCHEDULED || settlement.serviceType === ServiceType.PER_REQUEST),
              then: Yup.string().required(t('fieldRequired')),
            }),
          }}
          onSaved={async () => reloadSettlements()}
        />
      )}
      {settlement.settlementStatus === 'TO_BE_ACCEPTED' && <GreyButton label={t('accept')} onClick={() => accept()} />}
      {settlement.settlementStatus === 'TO_BE_ACCEPTED' && <GreyButton label={t('discard')} onClick={() => discard()} />}
      {settlement.settlementStatus === 'UNSETTLED' && settlement.receivedMass != null && (
        <AggregateCommandButton
          aggregateName='SettlementAggregate'
          aggregateId={settlement.settlementId!}
          commandName='settle'
          buttonLabel={t('settle')}
          forceConfirmation={true}
          confirmationMessage={t('settleMassDiscrepancyErrorInfo')}
          onSaved={async () => reloadSettlements()}
        />
      )}
      {settlement.lastModifiedBy != null && (
        <TextLabel value={`${t('lastModified')}: ${settlement.lastModifiedBy} / ${DateTimeService.isoStringDateToDateTime(settlement.lastModifiedDate)}`} />
      )}
    </Stack>
  );
};
