import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { DataTable, RenderIf, Snackbar, Toolbar } from '@lib/ui-components';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { dateTimeFormat } from '@lib/api-interface';
import { OtherReportType } from '../../../../libs/api-interface/models/report/other/other-report-type.enum';
import { CreateOtherReportButton } from './create-other-report-button';
import { useWebSocketState } from '../../../../state/web-socket-state';
import { ReportRowOptionsButtons } from '../report-row-options-buttons';
import { useAccessedReports } from '../../../../data-source-hooks/useAccessedReports';

export const OtherReports = () => {
  const { t } = useTranslation();
  const webSocketState = useWebSocketState();
  const [tableRefreshKey, setTableRefreshKey] = useState(0);
  const [showReportGenerationStartedMessage, setShowReportGenerationStartedMessage] = React.useState(false);
  const [accessedReportsQuery] = useAccessedReports();
  const accessedReports = accessedReportsQuery.data ?? [];
  const [selectedReportType, setSelectedReportType] = useState(
    Object.values(OtherReportType).find((type) => accessedReports.includes(type)) ?? OtherReportType.SUBCONTRACTOR_DECISIONS,
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTableRefreshKey((prevKey) => prevKey + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [webSocketState.reportGeneratedRefreshKey]);

  const createReportButton = React.useMemo(() => {
    return (
      <CreateOtherReportButton
        reportType={selectedReportType}
        onSaved={async () => {
          setTableRefreshKey((prevState) => prevState + 1);
          setShowReportGenerationStartedMessage(true);
        }}
      />
    );
  }, [selectedReportType]);

  const backgroundGenerationStartedMessage = React.useMemo(() => {
    return (
      <RenderIf true={showReportGenerationStartedMessage}>
        <Snackbar message={t('reportGenerationStarted')} severity='info' onClose={() => setShowReportGenerationStartedMessage(false)} />
      </RenderIf>
    );
  }, [showReportGenerationStartedMessage, t]);

  return (
    <>
      <Toolbar
        left={[
          <FormControl variant='standard' fullWidth>
            <InputLabel>{t('type')}</InputLabel>
            <Select defaultValue={selectedReportType} onChange={(event) => setSelectedReportType(event.target.value as OtherReportType)}>
              {Object.values(OtherReportType)
                .filter((val) => accessedReports.includes(val))
                .map((val) => (
                  <MenuItem key={`OtherReportType.${val}`} value={val}>
                    {t(`OtherReportType.${val}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>,
        ]}
        right={[createReportButton]}
      />
      <DataTable
        refreshKey={tableRefreshKey}
        modelDef={{
          modelName: 'reportViews',
        }}
        fetchFilters={{ type: selectedReportType }}
        initialSort={{ columnIndex: 1, ascending: false }}
        columns={[
          { label: t('reportFile'), key: 'fileName', type: 'text' },
          { label: t('creationDate'), key: 'creationDate', type: 'date', dateFormat: dateTimeFormat },
        ]}
        rowOptions={[
          {
            renderer: (row) => <ReportRowOptionsButtons {...row} />,
          },
        ]}
      />
      {backgroundGenerationStartedMessage}
    </>
  );
};
