import axios from 'axios';
import { useUserState } from '../state/UserState';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RolesEnum } from '../domain/enums/roles.enum';

export const useAccessedReports = () => {
  const userState = useUserState();
  const queryKey = ['UserAccessedReports', userState.userId];
  const queryClient = useQueryClient();

  if (!userState.roles.includes(RolesEnum.ORGANIZATION)) {
    return [
      {
        data: [],
        isLoading: false,
        isError: false,
        error: null,
        status: 'success',
      },
      async () => Promise.resolve(),
    ] as const;
  }

  const query = useQuery(
    queryKey,
    async () => {
      const response = await axios.get(`${process.env['REACT_APP_API_URL']}/api/account/${userState.userId}/accessed-reports`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data);
      }
    },
    {
      staleTime: 2000,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return [query, invalidateQuery] as const;
};
