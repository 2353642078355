import React, { useCallback, useMemo, useState } from 'react';
import { ApiCallButton, Button, DataTable, Dialog, HStack, Panel } from '@lib/ui-components';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { HateoasRestApiClientService } from '@lib/common-sdk';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryResultRenderer } from '../../../../libs/ui-components/components/query-result-renderer';
import { FinancialReportType } from '@lib/api-interface';
import { BdoReportType } from '../../../../libs/api-interface/models/report/bdo-report-type.enum';
import { MonthClosingReportType } from '../../../../libs/api-interface/models/report/month-closing-report-type.enum';
import { OtherReportType } from '../../../../libs/api-interface/models/report/other/other-report-type.enum';

export interface AccountAssignedReport {
  accountId: string;
  report: string;
}

export interface AssignProjectsButtonProps {
  accountId: string;
  accountName: string;
  onSaved: (rows: any[]) => void;
}

export const AssignReportsButton = ({ accountId, accountName, onSaved }: AssignProjectsButtonProps) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  return (
    <>
      <Button onClick={() => setOpenDialog(true)} label={t('accessReports')} />
      <Dialog open={openDialog} maxWidth='lg' fullWidth={true}>
        <DialogTitle sx={{ margin: 'auto' }}>
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AssignReportsButtonDialogContentWithDataFetcher
            accountId={accountId}
            accountName={accountName}
            onSaved={(rows) => {
              handleCloseDialog();
              onSaved(rows);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const AssignReportsButtonDialogContentWithDataFetcher = ({ accountId, accountName, onSaved }: AssignProjectsButtonProps) => {
  const queryKey = ['accountAccessedReports', 'byAccountId', accountId];
  const query = useQuery(queryKey, () =>
    HateoasRestApiClientService.findAll<AccountAssignedReport>('accountAccessedReports', { accountId }, undefined, {
      search: 'byAccountId',
    }),
  );

  return (
    <QueryResultRenderer
      query={query}
      render={(result) => {
        return <AssignReportsButtonDialogContent accountId={accountId} reports={result.response!} onSaved={onSaved} accountName={accountName} />;
      }}
    />
  );
};

export interface AssignReportsButtonDialogProps {
  accountId: string;
  accountName: string;
  onSaved: (rows: any[]) => void;
  reports: AccountAssignedReport[];
}

const AssignReportsButtonDialogContent = ({ accountId, onSaved, reports, accountName }: AssignReportsButtonDialogProps) => {
  const { t } = useTranslation();
  const queryKey = ['accountAccessedReports', 'byAccountId', accountId];
  const queryClient = useQueryClient();
  const [selectedRows, setSelectedRows] = useState(new Array<any>());
  const [refreshKey, setRefreshKey] = useState(0);

  const handleSelectedRowsChange = useCallback((newSelectedRows: any[]) => {
    function areArraysEqual(arr1: any[], arr2: any[]): boolean {
      if (arr1.length !== arr2.length || arr2.length === 0) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].projectId !== arr2[i].projectId) {
          return false;
        }
      }
      return true;
    }

    if (!areArraysEqual(selectedRows, newSelectedRows)) {
      setSelectedRows(newSelectedRows);
    }
  }, []);

  const dataTable = useMemo(
    () => (
      <DataTable
        columns={[
          { key: 'translatedName', label: t('name'), type: 'text' },
          {
            key: 'sortPriority',
            label: '',
            type: 'numeric',
            hidden: true,
          },
        ]}
        initialSort={{ columnIndex: 1, ascending: true }}
        noFilters={true}
        refreshKey={refreshKey}
        data={[
          {
            report: FinancialReportType.FINANCIAL_INCOME_AND_COSTS,
            translatedName: t('ReportType.FINANCIAL_INCOME_AND_COSTS'),
            sortPriority: 0,
          },
          {
            report: FinancialReportType.FINANCIAL_MONTHLY,
            translatedName: t('ReportType.FINANCIAL_MONTHLY'),
            sortPriority: 1,
          },
          {
            report: FinancialReportType.FINANCIAL_ANNUAL,
            translatedName: t('ReportType.FINANCIAL_ANNUAL'),
            sortPriority: 2,
          },
          {
            report: FinancialReportType.FINANCIAL_MONTHLY_ACHIEVEMENTS,
            translatedName: t('ReportType.FINANCIAL_MONTHLY_ACHIEVEMENTS'),
            sortPriority: 3,
          },
          { report: BdoReportType.KPO_REPORT, translatedName: t('BdoReportType.KPO_REPORT'), sortPriority: 4 },
          { report: BdoReportType.KEO_REPORT, translatedName: t('BdoReportType.KEO_REPORT'), sortPriority: 5 },
          {
            report: MonthClosingReportType.MONTH_CLOSING_MISSING_INVOICES,
            translatedName: t('ReportType.MONTH_CLOSING_MISSING_INVOICES'),
            sortPriority: 6,
          },
          {
            report: OtherReportType.SUBCONTRACTOR_DECISIONS,
            translatedName: t('OtherReportType.SUBCONTRACTOR_DECISIONS'),
            sortPriority: 7,
          },
          {
            report: OtherReportType.INFRASTRUCTURES,
            translatedName: t('OtherReportType.INFRASTRUCTURES'),
            sortPriority: 8,
          },
        ]}
        selectable={true}
        selectableOptions={{
          onSelectedRowsChange: handleSelectedRowsChange,
          initialSelections: reports,
          comparatorField: 'report',
        }}
      />
    ),
    [refreshKey],
  );

  return (
    <Panel header={`${accountName} ${t('hasAccessToReports')}`}>
      {dataTable}
      <HStack align={'right'}>
        <ApiCallButton
          variant={'contained'}
          color={'secondary'}
          url={'/api/account/access-reports'}
          payload={{ accountId, reports: selectedRows.map((row) => row.report) }}
          onCompleted={async () => {
            onSaved(selectedRows);
            await queryClient.invalidateQueries(queryKey);
            await queryClient.invalidateQueries(['UserAccessedReports', accountId]);
            setRefreshKey((prevState) => prevState + 1);
          }}
          buttonLabel={t('save')}
        />
      </HStack>
    </Panel>
  );
};
