import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export function communeServiceValidationSchema(t: TFunction<'common', undefined>, editing?: boolean) {
  type TF = Yup.TestFunction<string | undefined, AnyObject>;

  function isEmpty(value?: string) {
    return value == null || value.trim().length === 0;
  }

  function isPositive(value?: string) {
    if (isEmpty(value)) {
      return true;
    }
    const num = parseInt(value!, 10);
    return num > 0;
  }

  const afterActivationDate: TF = function (value) {
    const { activationDate } = this.parent;

    if (isEmpty(value)) {
      return true;
    }
    if (isEmpty(activationDate)) {
      return true;
    }
    return value! >= activationDate;
  };

  const deactivationDateAfterActivationDate: TF = function (value) {
    const { activationDate } = this.parent;

    if (isEmpty(value)) {
      return true;
    }
    if (isEmpty(activationDate)) {
      return true;
    }
    return value! >= activationDate;
  };

  let creatingValidation = {};
  if (!editing) {
    creatingValidation = {
      communePrice: Yup.object({
        price: Yup.string().required(t('fieldRequired')),
      }),
      declaredPrice: Yup.string().required(t('fieldRequired')),
    };
  }

  return {
    ...creatingValidation,
    collectionInfrastructureServiceId: Yup.string().required(t('fieldRequired')),
    collectionInfrastructureCount: Yup.string().required(t('fieldRequired')).test('is-collectionInfrastructureCount-positive', t('positiveNumberRequired'), isPositive),
    collectionInfrastructureOutputMass: Yup.string().required(t('fieldRequired')),
    activationDate: Yup.string().required(t('fieldRequired')),
    activeFromDate: Yup.string().test('is-active-from-date-after-activation-date', t('activeFromDateBeforeActivationDateRequired'), afterActivationDate),
    deactivationDate: Yup.string().test('is-deactivation-date-after-activation-date', t('deactivationDateAfterActivationDateRequired'), deactivationDateAfterActivationDate),
    declaredTransports: Yup.string().required(t('fieldRequired')),
    monthsCount: Yup.string().required(t('fieldRequired')),
    paymentDay: Yup.string().required(t('fieldRequired')),
    paymentPeriod: Yup.string().required(t('fieldRequired')),
    paymentDue: Yup.string().required(t('fieldRequired')),
    bankAccountNumber: Yup.string().required(t('fieldRequired')),
    bankPaymentReceiver: Yup.string().required(t('fieldRequired')),
  };
}
