import { Button, Panel, RenderIf } from '@lib/ui-components';
import React from 'react';
import { CollapsedArea, LabelDiv, StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { useTranslation } from 'react-i18next';
import { CollapseIcon, ExpandIcon } from '@shared/service-table';
import { useQueryClient } from '@tanstack/react-query';
import i18n from 'i18next';
import { ServiceBar } from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-bar';
import { Grid, Icon, Tooltip } from '@mui/material';
import {
  priceItem,
  variableAnnotationsFieldsReloadById,
} from '../../projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { ServicesSettlementTableFooter } from './services-settlement-table-footer';
import { CanceledIcon, ForApprovalIcon, InvoicedIcon, SettledIcon, UnsettledIcon } from './icons';
import { ServicePriceType, ServiceType, SettlementDetailsView, SettlementViewStatus } from '@lib/api-interface';
import { PriorityHigh } from '@mui/icons-material';

export interface SettlementTableProps {
  settlement: SettlementDetailsView;
  reloadSettlements: () => void;
  showButtons?: boolean;
}

export function ServicesSettlementTable({ settlement, reloadSettlements, showButtons = true }: SettlementTableProps) {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [expandCollapseSwitch, setExpandCollapseSwitch] = React.useState(true);
  const queryClient = useQueryClient();

  const priceTypes: ServicePriceType[] = [
    settlement.settings.clientCollectionPrice?.priceType,
    settlement.settings.clientTransportPrice?.priceType,
    settlement.settings.subcontractorCollectionPrice?.priceType,
    settlement.settings.subcontractorTransportPrice?.priceType,
  ];

  const regex = /(\d{4})-(\d{2})/g;
  const match = regex.exec(settlement.yearMonth!) || [null, null, null];
  const monthLabel = translations.common[`shortMonth.${match[2]}`];
  const dateLabel = `${monthLabel} ${match[1]}`;

  const volumeHeaders = priceTypes.includes(ServicePriceType.M3) ? [{ label: t('volumeReceived') }] : [];
  const volumeValues = priceTypes.includes(ServicePriceType.M3) ? [{ value: settlement.receivedVolume }] : [];
  const transportPriceHeaders =
    settlement.serviceView.serviceType !== 'ADDITIONAL'
      ? [{ label: t('Projects.ViewProject.clientTransportPrice') }, { label: t('Projects.ViewProject.subcontractorTransportPrice') }]
      : [];
  const transportPriceValues =
    settlement.serviceView.serviceType !== 'ADDITIONAL'
      ? [
          priceItem(settlement.settings.clientTransportPrice, { priceTypeVisible: true, translations }),
          priceItem(settlement.settings.subcontractorTransportPrice, {
            priceTypeVisible: true,
            translations,
          }),
        ]
      : [];

  function getNavResourceNumber() {
    if (settlement.serviceView.serviceType === 'ADDITIONAL') {
      return settlement.serviceView.navResourceNumber;
    }

    let result = settlement.clientCollectionNavResourceNumber != null ? settlement.clientCollectionNavResourceNumber : '';
    result +=
      settlement.subcontractorCollectionNavResourceNumber != null
        ? result !== ''
          ? ', ' + settlement.subcontractorCollectionNavResourceNumber
          : settlement.subcontractorCollectionNavResourceNumber
        : '';
    return result;
  }

  function getNavResourceName() {
    if (settlement.serviceView.serviceType === 'ADDITIONAL') {
      return settlement.serviceView.navResourceName;
    }
    let result = settlement.clientCollectionNavResourceName != null ? settlement.clientCollectionNavResourceName : '';
    result +=
      settlement.subcontractorCollectionNavResourceName != null
        ? result !== ''
          ? ', ' + settlement.subcontractorCollectionNavResourceName
          : settlement.subcontractorCollectionNavResourceName
        : '';
    return result;
  }

  return (
    <StyledTableBorder data-settlement-id={settlement.settlementId} data-service-id={settlement.serviceId}>
      <Panel justifyContent='space-between' direction='row' onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}>
        <LabelDiv>
          {settlement.settlementStatus === SettlementViewStatus.SETTLED && <SettledIcon />}
          {settlement.settlementStatus === SettlementViewStatus.UNSETTLED && <UnsettledIcon />}
          {settlement.settlementStatus === SettlementViewStatus.TO_BE_ACCEPTED && <ForApprovalIcon />}
          {settlement.settlementStatus === SettlementViewStatus.INVOICED && <InvoicedIcon />}
          {settlement.settlementStatus === SettlementViewStatus.CANCELLED && <CanceledIcon />}
          {dateLabel}
        </LabelDiv>
        <Button
          label={expandCollapseSwitch ? t('Projects.ViewProject.expand') : t('Projects.ViewProject.collapse')}
          variant='text'
          endIcon={expandCollapseSwitch ? <ExpandIcon /> : <CollapseIcon />}
          onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}
        />
      </Panel>
      <TableAdaptiveArea isDeactivated={settlement.settlementStatus === SettlementViewStatus.CANCELLED}>
        <ServiceBar
          headers={[
            { label: t('projectNumber') },
            { label: t('projectName') },
            { label: t('clientName') },
            { label: t('branch') },
            { label: t('locality') },
            { label: t('street') },
            { label: t('activeBdo') },
            { label: t('generatesKpo') },
            { label: t('bdoStatus') },
            { label: t('kpoNumber') },
            { label: t('serviceType') },
            { label: t('onDemand') },
            { label: t('hasComplaint') },
          ]}
          items={[
            { value: settlement.projectNumber, options: { bold: true } },
            { value: settlement.projectName, options: { bold: true } },
            { value: settlement.companyName },
            { value: settlement.branchName },
            { value: settlement.registrationAddress!.town },
            { value: settlement.registrationAddress!.street },
            { value: settlement.serviceView.project.terms?.general?.bdo, type: 'boolean' },
            { value: settlement.serviceView.generatesKpo, type: 'boolean' },
            { value: settlement.kpoStatus },
            { value: settlement.kpoNumber },
            { value: translations.common[`serviceType.${settlement.serviceView.serviceType}`] },
            { value: settlement.serviceView.onDemand, type: 'boolean' },
            { value: settlement.hasComplaint, type: 'boolean' },
          ]}
        />
        <RenderIf true={settlement.serviceType != ServiceType.ADDITIONAL}>
          <ServiceBar
            headers={[
              { label: t('wasteCode') },
              { label: t('pix') },
              { label: t('wasteName') },
              { label: t('ownDescriptionOfWaste') },
              { label: t('resourceName') },
              { label: t('number') },
              { label: t('collectionMethod') },
              { label: t('containerAmount') },
              { label: t('forUtilization') },
              { label: t('reportDate') },
              { label: t('implementationDate') },
            ]}
            items={[
              { value: settlement.serviceView.projectWaste?.wasteCode, options: { bold: true } },
              { value: settlement.settings.pix, type: 'boolean' },
              { value: settlement.serviceView.projectWaste?.wasteDescription, options: { bold: true } },
              { value: settlement.serviceView.projectWaste?.projectWasteDescription },
              {
                value: settlement.serviceView.resourceInBulk ? t('Model.Infrastructure.InfrastructureCategory.IN_BULK') : settlement.serviceView.projectInfrastructureService?.name,
                options: { bold: true },
              },
              {
                value: settlement.serviceView.resourceInBulk ? '-' : settlement.serviceView.infrastructureCount,
                options: { bold: true },
              },
              {
                value: settlement.serviceView.collectionInfrastructureService?.name,
                options: { bold: true },
              },
              { value: settlement.serviceView.collectionInfrastructureCount, options: { bold: true } },
              { value: settlement.serviceView.utilization, type: 'boolean' },
              { value: settlement.serviceView.settlementType === 'PER_MONTH' ? '-' : settlement.reportDate },
              { value: settlement.serviceView.settlementType === 'PER_MONTH' ? '-' : settlement.implementationDate },
            ]}
          />
        </RenderIf>
        <RenderIf true={settlement.serviceType == ServiceType.ADDITIONAL}>
          <Grid container>
            <Grid item xs={8}>
              <ServiceBar
                headers={[
                  { label: t('navResourceNumber') },
                  { label: t('navResourceName') },
                  { label: t('serviceDesc') },
                  { label: t('servicePixRelated') },
                  { label: t('reportDate') },
                  { label: t('implementationDate') },
                ]}
                items={[
                  { value: getNavResourceNumber() },
                  { value: getNavResourceName() },
                  { value: settlement.serviceView.description },
                  { value: settlement.settings.pix, type: 'boolean' },
                  { value: settlement.serviceView.settlementType === 'PER_MONTH' ? '-' : settlement.reportDate },
                  { value: settlement.serviceView.settlementType === 'PER_MONTH' ? '-' : settlement.implementationDate },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <ServiceBar headers={[{ label: t('variableAnnotation') }, {}]} items={variableAnnotationsFieldsReloadById(settlement, t, queryClient)} />
            </Grid>
          </Grid>
        </RenderIf>
        <CollapsedArea collapse={expandCollapseSwitch}>
          <ServiceBar
            headers={[{ label: t('settledSubcontractorName') }, { label: t('settledTransportEntity') }, { label: t('transporterKPO') }, { label: t('receiverKPO') }]}
            items={[
              { value: settlement.serviceView.subcontractor },
              { value: settlement.serviceView.transporter },
              { value: settlement.serviceView.transporterKpo },
              { value: settlement.serviceView.receiverKpo },
            ]}
          />
          <ServiceBar
            headers={[
              { label: t('settlementType') },
              { label: t('Projects.ViewProject.clientCollectionPrice') },
              { label: t('Projects.ViewProject.subcontractorCollectionPrice') },
              ...transportPriceHeaders,
              { label: t('paymentMethodCode') },
              { label: t('separatedInvoice') },
              { label: t('invoicingMethod') },
              { label: t('constantCost') },
              { label: t('organizationAsSide') },
              { label: t('plImport') },
              { label: t('plExport') },
            ]}
            items={[
              { value: translations.common[`settlementType.${settlement.serviceView.settlementType}`] },
              priceItem(settlement.settings.pix ? settlement.pixPrice : settlement.settings.clientCollectionPrice, {
                priceTypeVisible: true,
                translations,
              }),
              priceItem(settlement.settings.subcontractorCollectionPrice, {
                priceTypeVisible: true,
                translations,
              }),
              ...transportPriceValues,
              { value: translations.common[`paymentMethodCode.${settlement.serviceView.paymentMethod}`] },
              { value: settlement.serviceView.separateInvoice, type: 'boolean' },
              { value: translations.common[`invoicingMethod.${settlement.serviceView.invoicingMethod}`] },
              { value: settlement.serviceView.constantCost, type: 'boolean' },
              { value: settlement.settings.organizationAsSide, type: 'boolean' },
              { value: settlement.serviceView.plImport, type: 'boolean' },
              { value: settlement.serviceView.plExport, type: 'boolean' },
            ]}
          />
          <RenderIf true={settlement.serviceType != ServiceType.ADDITIONAL}>
            <Grid container>
              <Grid item xs={6}>
                <ServiceBar
                  headers={[{ label: t('navResourceNumber') }, { label: t('navResourceName') }]}
                  items={[{ value: getNavResourceNumber() }, { value: getNavResourceName() }]}
                />
              </Grid>
              <Grid item xs={6}>
                <ServiceBar headers={[{ label: t('variableAnnotation') }, {}]} items={variableAnnotationsFieldsReloadById(settlement, t, queryClient)} />
              </Grid>
            </Grid>
          </RenderIf>
          <ServiceBar
            headers={[
              { label: t('estimatedMass') },
              { label: t('reportedWeightExceed') },
              { label: t('reportedWeight') },
              { label: t('receivedMass') },
              { label: t('estimatedQuantities') },
              { label: t('reportedQuantitiesExceed') },
              { label: t('reportedQuantities') },
              { label: t('quantityReceived') },
              ...volumeHeaders,
              { label: t('transportPlan') },
              { label: t('transportCompleted') },
            ]}
            items={[
              { value: settlement.serviceView.onDemand ? '-' : settlement.estimatedMass },
              { value: settlement.exceedMass ?? 0 },
              { value: settlement.serviceView.serviceType === 'PER_REQUEST' ? settlement.reportedMass : '-' },
              {
                value: settlement.receivedMass,
                options: {
                  color: (() => {
                    if (settlement.serviceView?.project?.terms?.allowedMassDiff !== null) {
                      return settlement.massDiscrepancyInRange ? 'green' : 'red';
                    }
                  })(),
                  additionalElement: (() => {
                    if (settlement.bdoFinalMassNotMatchedWithReceivedMass === true) {
                      return (
                        <Tooltip title={t('massFromBdoNotMatchedWithClientMass')}>
                          <Icon sx={{ verticalAlign: 'text-bottom' }}>
                            <PriorityHigh color={'warning'} fontSize={'small'} />
                          </Icon>
                        </Tooltip>
                      );
                    }
                  })(),
                },
              },
              { value: settlement.serviceView.serviceType === 'PER_REQUEST' ? '-' : settlement.estimatedQuantities },
              { value: settlement.exceedContainers ?? 0 },
              { value: settlement.serviceView.serviceType === 'PER_REQUEST' ? settlement.reportedQuantities : '-' },
              { value: settlement.receivedQuantities },
              ...volumeValues,
              { value: settlement.serviceView.onDemand ? '1' : `${settlement.completedTransports} / ${settlement.estimatedTransports}` },
              { value: settlement.reportedTransports },
            ]}
          />
        </CollapsedArea>
        <RenderIf true={showButtons}>
          <ServicesSettlementTableFooter settlement={settlement} t={t} reloadSettlements={reloadSettlements} />
        </RenderIf>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
}
