import React from 'react';
import { Panel } from '@lib/ui-components';
import { CompanyViewModel } from '@lib/api-interface';
import {
  AssignedProjectsToEntityTypes,
  CompanyBranchAssignedProjects,
} from '../../shared/company-branches/company-branch-view/company-branch-data/company-branch-assigned-projects';

export interface ClientAssignedProjectsProps {
  company: CompanyViewModel;
}

export const ClientAssignedProjects = (props: ClientAssignedProjectsProps) => {
  return (
    <Panel borderType='raised'>
      <CompanyBranchAssignedProjects entityType={AssignedProjectsToEntityTypes.COMPANY} entityId={props.company.companyId!} roleClient={props.company.roleClient === true} />
    </Panel>
  );
};
