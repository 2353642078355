import React from 'react';
import { Panel, RenderIf, TextLabel } from '@lib/ui-components';
import { StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ServiceBar } from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-bar';
import {
  priceItem,
  scheduleServiceBar,
} from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/shared';
import { ServiceStatusLabel } from '../../../../organization/projects/view-project/clients-tab/headquarters-branches/project-branch-view/branch-services/shared-components/service-status-label';
import { Stack } from '@mui/material';

export interface CommuneTableProps {
  serviceViewDetails: any;
  reloadServices: () => void;
}

export function CommuneTable(props: CommuneTableProps) {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const service = props.serviceViewDetails;
  service.calculatedPrice = service.monthsCount && service.declaredPrice ? (service.monthsCount * service.declaredPrice).toFixed(2) : '-';

  return (
    <StyledTableBorder>
      <Panel justifyContent="space-between" direction="row">
        <ServiceStatusLabel active={service.active} acceptanceState={service.acceptanceState}
                            started={service.started} />
      </Panel>
      <TableAdaptiveArea isDeactivated={!service.active || service.acceptanceState === 'INITIALIZED'}>
        <ServiceBar
          headers={[
            { label: t('resourceName') },
            { label: t('estimatedMass') },
            { label: t('number') },
            { label: t('additionalDescription') },
            { label: t('activationDate') },
            { label: t('deactivationDate') },
          ]}
          items={[
            { value: service.collectionInfrastructureService.name, options: { bold: true } },
            { value: service.collectionInfrastructureService.estimatedMass, options: { bold: true } },
            { value: service.collectionInfrastructureCount, options: { bold: true } },
            { value: service.description, options: { bold: true } },
            { value: service.activationDate },
            { value: service.deactivationDate },
          ]}
        />
        <ServiceBar
          headers={[
            { label: t('resolutionContainerPrice') },
            { label: t('declaredTransports') },
            { label: t('monthsCount') },
            { label: t('declaredPrice') },
            { label: t('calculatedPrice') },
            { label: t('nextPaymentDate') },
          ]}
          items={[
            priceItem(service.currentSettings?.communePrice, { translations }),
            { value: service.declaredTransports?.toFixed(2) },
            { value: service.monthsCount?.toFixed(2) },
            { value: service.declaredPrice?.toFixed(2) },
            { value: service.calculatedPrice },
            { value: service.nextPaymentDate },
          ]}
        />
        {scheduleServiceBar(t, service, false)}
        <Stack direction={'row'} marginLeft={2} marginBottom={2} spacing={6}>
          <RenderIf true={service.acceptanceState === 'ACCEPTED'}>
            <TextLabel value={`${t('acceptanceDate')}: ${service.acceptanceDate}`} />
          </RenderIf>
        </Stack>
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
}
