import React from 'react';
import { Button, Panel } from '@lib/ui-components';
import { CollapsedArea, StyledTableBorder, TableAdaptiveArea } from '@shared/general';
import { CollapseIcon, ExpandIcon } from '@shared/service-table';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ServiceBar } from '../shared-components/service-bar';
import { priceItem, scheduleServiceBar } from '../shared-components/shared';
import { ServiceTableFooter } from '../shared-components/service-table-footer';
import { CommuneServiceButton } from './commune-service-button';
import { ServiceButtonMode } from '../service-button-props';
import { ServiceStatusLabel } from '../shared-components/service-status-label';

export interface CommuneTableProps {
  serviceViewDetails: any;
  reloadServices: () => void;
}

export default function CommuneTable(props: CommuneTableProps) {
  const { t } = useTranslation();
  const translations: any = i18n.getDataByLanguage(i18n.language);
  const [expandCollapseSwitch, setExpandCollapseSwitch] = React.useState(true);
  const service = props.serviceViewDetails;
    service.calculatedPrice = service.monthsCount && service.declaredPrice ? (service.monthsCount * service.declaredPrice).toFixed(2) : '-';

  return (
    <StyledTableBorder>
      <Panel justifyContent='space-between' direction='row' onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}>
        <ServiceStatusLabel active={service.active} acceptanceState={service.acceptanceState} started={service.started} />
        <Button
          label={expandCollapseSwitch ? t('Projects.ViewProject.expand') : t('Projects.ViewProject.collapse')}
          variant='text'
          endIcon={expandCollapseSwitch ? <ExpandIcon /> : <CollapseIcon />}
          onClick={() => setExpandCollapseSwitch((prevState) => !prevState)}
        />
      </Panel>
      <TableAdaptiveArea isDeactivated={!service.active || service.acceptanceState === 'INITIALIZED'}>
        <ServiceBar
          headers={[{ label: t('resourceName') }, { label: t('number') }, { label: t('additionalDescription') }, { label: t('activationDate') }, { label: t('deactivationDate') }]}
          items={[
            { value: service.collectionInfrastructureService.name, options: { bold: true } },
            { value: service.collectionInfrastructureCount, options: { bold: true } },
            { value: service.description, options: { bold: true } },
            { value: service.activationDate },
            { value: service.deactivationDate },
          ]}
        />
        <ServiceBar
          headers={[
            { label: t('resolutionContainerPrice') },
            { label: t('declaredTransports') },
            { label: t('monthsCount') },
            { label: t('declaredPrice') },
            { label: t('calculatedPrice') },
            { label: t('paymentPeriod') },
            { label: t('paymentDue') },
            { label: t('paymentDay') },
            { label: t('nextPaymentDate') },
          ]}
          items={[
            priceItem(service.currentSettings?.communePrice, { translations }),
            { value: service.declaredTransports?.toFixed(2) },
            { value: service.monthsCount?.toFixed(2) },
            { value: service.declaredPrice?.toFixed(2) },
            { value: service.calculatedPrice },
            { value: translations.common[`paymentPeriod.${service.paymentPeriod}`] },
            { value: translations.common[`paymentDue.${service.paymentDue}`] },
            { value: service.paymentDay === 'DAY_LAST' ? t('paymentDay.DAY_LAST') : service.paymentDay?.substring(4) },
            { value: service.nextPaymentDate },
          ]}
        />
        {scheduleServiceBar(t, service)}
        <CollapsedArea collapse={expandCollapseSwitch}>
          <ServiceBar
            headers={[{ label: t('bankAccountNumber') }, { label: t('bankPaymentReceiver') }, { label: t('bankPaymentTitle') }]}
            items={[{ value: service.bankAccountNumber }, { value: service.bankPaymentReceiver }, { value: service.bankPaymentTitle }]}
          />
        </CollapsedArea>
        <ServiceTableFooter
          service={service}
          t={t}
          reloadServices={props.reloadServices}
          editButton={
            <CommuneServiceButton serviceButtonMode={ServiceButtonMode.EDIT} service={service} onSaved={async () => props.reloadServices()} reloadServices={props.reloadServices} />
          }
          copyButton={
            <CommuneServiceButton serviceButtonMode={ServiceButtonMode.COPY} service={service} onSaved={async () => props.reloadServices()} reloadServices={props.reloadServices} />
          }
        />
      </TableAdaptiveArea>
    </StyledTableBorder>
  );
}
