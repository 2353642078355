import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataTable, DeleteModelButton, GetContentButton, Panel } from '@lib/ui-components';
import { CompanyViewModel } from '@lib/api-interface';

export interface ClientCommercialDocProps {
  company: CompanyViewModel;
}

export function ClientCommercialDoc(props: ClientCommercialDocProps) {
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const { t } = useTranslation();

  return (
    <Panel borderType='none'>
      <DataTable
        refreshKey={tableReloadKey}
        modelDef={{ modelName: 'companyFileViews' }}
        fetchFilters={{ companyId: props.company.companyId, type: 'TRADE_DOCUMENT' }}
        columns={[
          {
            key: 'fileName',
            label: t('file'),
            type: 'text',
          },
          {
            key: 'companyBranch.name',
            label: t('Model.CompanyBranch.name'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.town',
            label: t('locality'),
            type: 'text',
          },
          {
            key: 'companyBranch.registrationAddress.street',
            label: t('street'),
            type: 'text',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
          },
        ]}
        rowOptions={[
          {
            renderer: (row) => (
              <GetContentButton
                label={t('Companies.CompanyView.CompanyFiles.commercial.viewFile')}
                icon={<VisibilityIcon />}
                resourcePath={`api/resource/companyFileViews/${row.id}/content`}
              />
            ),
          },
          {
            renderer: (row) => <DeleteModelButton modelName={'companyFileViews'} id={row.id} onDeleted={async () => setTableReloadKey(tableReloadKey + 1)} />,
          },
        ]}
      />
    </Panel>
  );
}
